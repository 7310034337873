/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { UnitCategoryService } from 'src/app/modules/unit/unit-type-page/unit-category.service';
import { UnitTypeService } from 'src/app/modules/unit/unit-type-page/unit-type.service';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { PersonService } from './person.service';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { required, uniqueGrid } from 'src/app/shared/validators';
import { DialogOptions, PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { first, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Unit } from 'src/app/model/unit';
import { of } from 'rxjs';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { MyInjector } from 'src/app/app.module';
import { Params, Router } from '@angular/router';
import { ServiceRequestPageComponent } from 'src/app/modules/crm/service-request-page/service-request-page.component';
import { OmcService } from 'src/app/modules/agent/omc.service';
import { HttpParams } from '@angular/common/http';
import { uuid } from 'src/app/model/abstract-object';
import { UnitPageComponent } from 'src/app/modules/unit/unit-page/unit-page.component';
import { FormPageComponent } from '../../shared/form/form-page/form-page.component';
import { Role } from 'src/app/model/role';
import { RoleService } from '../role-page/role.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { ActionGridComponent } from 'src/app/shared/action/action-grid.component';
import { SendEmailFormAction } from 'src/app/shared/action/send-email-form-action';
import { MemoField } from 'src/app/shared/memos/MemoField';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { MailService } from 'src/app/shared/mail-service';

@Component({
    selector: 'app-person-page',
    templateUrl: './person-page.component.html',
    styleUrls: ['./person-page.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [FormPageComponent],
})
export class PersonPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('people', PersonPageComponent, 'people').setViews(() => {
        return [
            {
                id: 'allPeople',
                name: 'All People',
                filterFields: {
                    lastName: null,
                    firstName: null,
                    keyword: null,
                },
                sort: 'createdAt'
            }
        ];
    });
    unclaimedHint = "When a user signs up with this email address, " +
        "they will be automatically matched to this person record and granted access to the OMC";

    claimedHint = "This person record is tied to a verified user account, the email address can no longer be changed";
    emailField = FormEmailComponent.make('Email', 'email', { formColumn: 2, hint: this.unclaimedHint });

    getUnitField() {
        if (!this.dialogRef) {
            return FormButtonComponent.makeNavDetailButton('Unit', 'unit.name', 'unitId', UnitPageComponent.navRoute, true);
        } else {
            return FormTextComponent.make('Unit', 'unit.name', { readonly: true, sendServer: false });
        }
    }

    mailItemGrid = this.mailSvc.getMailItemGrid(this.dataSvc, 'recipient');

    forceTeamId: uuid;

    unitRowFactory(o: PersonUnitRole) {
        const roleField = FormPicklistComponent.make('Role', 'roleId', 'role',
            { items: [] },
            { cellOpts: { width: '20%' }, validators: [required] }
        );
        if (o?.id) {
            roleField.setPicklistItems(this.roleList);
        } else {
            roleField.setPicklistItems(this.roleList.filter(o => o.unitLinked))
        }

        return [
            FieldMaker.id({ visible: Field.noShow }),
            FormTextComponent.make('unitId', 'unitId', { visible: Field.noShow }),
            this.getUnitField(),
            roleField,
            FormTextComponent.make('Type', 'unit.type.name', { readonly: true, sendServer: false, cellOpts: { width: '8%' } }),
            FormTextComponent.make('Category', 'unit.category.name', { readonly: true, sendServer: false, cellOpts: { width: '8%' } }),
            FormTextComponent.make('Area', 'unit.area.name', { readonly: true, sendServer: false, cellOpts: { width: '8%' } }),
            FormDateComponent.make('From', 'startDate', { cellOpts: { width: '2%' } }),
            FormDateComponent.make('Until', 'endDate', { cellOpts: { width: '2%' } }),
            FieldMaker.notes({ cellOpts: { width: '50%' } }),
            FieldMaker.deleteGridRow(),
        ]

    }

    personUnitRoleField = FieldMaker.idHolder('personUnits');

    unitsField = new GridField({
        field: { value: 'units', cellOpts: { heading: 'Units' }, formColumn: 1, formRow: 2 },
        rowFactory: this.unitRowFactory.bind(this),
        //objFactory: () => of (new PersonUnitRole()),

        objFactory: () => {
            const dialogRef = this.dialog.open(PickDialogComponent,
                {
                    data: {
                        config: Unit.pickDialogFormConfig(),
                        service: this.unitSvc,
                        forceTeamId: this.forceTeamId,
                        showSearch: true,
                    }
                });
            return dialogRef.afterClosed().pipe(first()).pipe<PersonUnitRole>(
                map(this.plMapper)
            );
        }
    });
    /* .filter(o => !o.unitLinked) */
    roleGrid: GridField = new GridField({
        field: { value: 'roles', cellOpts: { heading: 'Roles' }, formColumn: 5, sendServer: false, visible: Field.formOnly },
        rowFactory: this.roleGridRow.bind(this),
        gridValidator: [uniqueGrid('roleId')],
        objFactory: () => of(new PersonUnitRole()),
    });

    roleList: Role[] = [];

    openRequestsLink = FormButtonComponent.makeNavButton('Open Requests',
        ServiceRequestPageComponent.navRoute, o => ({ personId: o.id, _sort: 'createdAt' }),
        { formColumn: 3, visible: Field.formOnly, value: 'open_requests_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    requestsLink = FormButtonComponent.makeNavButton('Total Requests',
        ServiceRequestPageComponent.navRoute, o => ({ personId: o.id, _sort: 'createdAt' }),
        { formColumn: 4, value: 'requests_count', visible: Field.formOnly, cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    actionGrid = ActionGridComponent.make('Person');
    memoField = new MemoField(this.dataSvc);

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc);

    fields = [
        FormTextComponent.make('Keyword Search', 'keyword', { visible: Field.noShow, sendServer: false }),
        FieldMaker.id(),
        FieldMaker.rev(),
        Person.getTitleField(),
        FormTextComponent.make('First Name', 'firstName'),
        FormTextComponent.make('Last Name', 'lastName', { validators: [required] }),
        FormTextComponent.make('Telephone', 'phone', { formColumn: 2 }),
        this.emailField,
        FormTextComponent.make('User Name', 'user.name', { disable: true, sendServer: false, formColumn: 2, cellOpts: { width: '10%' } }),
        FormTextAreaComponent.make('Mailing Address', 'address', { formColumn: 3 }),
        FieldMaker.notes({ formColumn: 4 }),
        this.openRequestsLink,
        this.requestsLink,
        this.unitsField,
        this.roleGrid,
        this.personUnitRoleField,
        FieldMaker.idHolder('mergeId'),
    ]

    config = new FormConfig({
        navRoute: PersonPageComponent.navRoute,
        title: $localize`People`,
        help: $localize`The members, owners, residents, directors of the Owner Management Company`,
        fieldSet: new FieldSet({
            fields: this.fields,
            formLayout: [
                { cells: [{ width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }] },
                { cells: [{ colspan: 5, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newEntry.bind(this),
        beforeEdit: this.beforeEdit.bind(this),
        beforeSave: this.beforeSave.bind(this),
        actions: [new NewPersonRequestAction(), new SendEmailFormAction(this.mailItemGrid)],
        tabFields: [this.actionGrid, this.memoField, this.mailItemGrid, this.attachmentGrid],
        setTeam: (teamId) => {
            this.forceTeamId = teamId;
            this.roleService.get(false, null, teamId).subscribe(roles => {
                roles.forEach(r => this.roleList.push(r as Role));
            });
        }
    });

    constructor(public dataSvc: PersonService,
        public roleService: RoleService, public typeService: UnitTypeService, public categoryService: UnitCategoryService,
        public dialog: MatDialog, protected unitSvc: UnitService, private mailSvc: MailService,
        @Optional() public dialogRef: MatDialogRef<PersonPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        super();
    }

    roleGridRow(o: PersonUnitRole) {
        const f = FormPicklistComponent.make('Role', 'roleId', 'role', { items: [] }, { validators: [required] });

        if (o && o.id) {
            f.override({ readonly: true });
            f.setPicklistItems(this.roleList);
        } else if (o) {
            f.setPicklistItems(this.roleList.filter(o => !o.unitLinked));
        }

        const deleter = FieldMaker.deleteGridRow();
        if (o?.unitId) {
            deleter.override({ disable: true });
        }
        return [FieldMaker.idHolder('id'), f, deleter];
    }

    newEntry() {
        this.emailField.defaultReadonly = false;
        this.emailField.hint = this.unclaimedHint;
        return of(new Person());
    }

    beforeSave() {
        const pur = [];
        const units = this.unitsField.getFormValue();
        units.forEach(u => pur.push(u));
        const roles = this.roleGrid.getFormValue();
        roles.forEach(u => pur.push(u));
        this.personUnitRoleField.control.setValue(pur);
    }

    beforeEdit(o: Person) {
        if (!o) {
            o = new Person();
        }

        if (o.userId) {
            this.emailField.hint = this.claimedHint;
            this.emailField.defaultReadonly = true;
        } else {
            this.emailField.hint = this.unclaimedHint;
            this.emailField.defaultReadonly = false;
        }
        o.roles = [];
        o.units = [];
        o.personUnits.forEach(pur => {
            if (pur.unit) {
                o.units.push(pur);
            } else {
                o.roles.push(pur);
            }
        })
        return o;
    }

    plMapper(itemPicked): PersonUnitRole {
        if (itemPicked) {
            const newItem = new PersonUnitRole();
            newItem.unitId = itemPicked.id;
            newItem.unit = itemPicked;
            return newItem;
        } else {
            return null;
        }
    }
}

export class NewPersonRequestAction implements IFormAction {
    name = $localize`Create Request`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'add_circle';
    approvalNeeded = false;
    disabled = false;
    approvalText = "";
    tipText: string;
    teamId: uuid;
    person: Person;

    action(person: Person) {
        const router = MyInjector.instance.get(Router);
        const omcSvc = MyInjector.instance.get(OmcService);

        omcSvc.get(false, new HttpParams().set('omcTeamId', this.teamId)).subscribe(result => {
            if (result.length === 1) {
                const parms = { personId: person.id, omcId: result[0].id } as Params;
                if (person.personUnits && person.personUnits.length > 0) {
                    parms.unitId = person.personUnits[0].unitId;
                }
                const route = ['/' + ServiceRequestPageComponent.navRoute.newRoute.path, parms];
                router.navigate(route);
            } else {
                console.error('Expected exactly one OMC');
            }
        })
        return of(this.person); // person passed in didn't have actions etc, cannot be reloaded...
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(person: Person) {
        this.teamId = person?.teamId;
        this.person = person;
        const cus = MyInjector.instance.get(CurrentUserService)
        if (cus.currentUser && cus.currentUser.inAgency && this.teamId) {
            this.show = true;
        } else {
            this.show = false;
        }

    }
}
