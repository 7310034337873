/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavRoute } from 'src/app/shared/NavRoute';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { PersonPageComponent } from '../person-page/person-page.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { Person } from 'src/app/model/person';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { TableComponent } from 'src/app/shared/table/table.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { Location } from '@angular/common';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { UnitPageComponent } from 'src/app/modules/unit/unit-page/unit-page.component';
import { TableComponent as TableComponent_1 } from '../../shared/table/table.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    standalone: true,
    imports: [FormsModule, MatButtonModule, TableComponent_1]
})
export class SearchPageComponent implements AfterViewInit {
    searchTermPerson = "";
    searchTermUnit = "";

    searching = false;

    static readonly navRoute = new NavRoute('search', SearchPageComponent, 'search')

    snackBarRef: MatSnackBarRef<TextOnlySnackBar> = null;
    @ViewChild(TableComponent) table: TableComponent;

    config: FormConfig;

    constructor( private msgService: MessageService, private http: HttpClient, private router: Router,
    private location: Location, private activatedRoute: ActivatedRoute) {
        this.showTable();
        this.getSearchParams(activatedRoute.snapshot.params);
        if (this.searchTermPerson || this.searchTermUnit) {
            this.searching = true;
        }
    }

    ngAfterViewInit(): void {
        this.activatedRoute.params.subscribe( params => {
            this.getSearchParams(params);
            if (params.person || params.unit) {
                this.doSearch();
            } else {
                window.setTimeout(() => this.table.loadData([]), 10);
            }
        })
    }
    getSearchParams(params: Params) {
        if (params.person) {
            this.searchTermPerson = params.person;
        }
        if (params.unit) {
            this.searchTermUnit = params.unit;
        }
    }

    handleKeyUp($event: KeyboardEvent) {
        this.table.loadData([]);
        if (this.snackBarRef) {
            this.snackBarRef.dismiss();
        }
        if ($event.key === 'Enter' && (this.searchTermPerson || this.searchTermUnit)) {
            this.navigateResult();
        }
    }

    canDeactivate() {
        return true;
    }

    navigateResult() {
        const params = {person: this.searchTermPerson, unit: this.searchTermUnit} as Params;
        this.router.navigate([SearchPageComponent.navRoute.url, params]);
    }

    doSearch() {

        if (this.searchTermPerson.trim().length < 3 && this.searchTermUnit.trim().length < 3) {
            const msg = new MsgDef($localize `Please enter at least 3 search characters`, 'warn')
            this.snackBarRef = this.msgService.show(msg);
            this.searching = false;
            return;
        }

        const url = AbstractHttpService.ajaxPath + 'search/people';
        const params = {keywordPerson: this.searchTermPerson, keywordUnit: this.searchTermUnit} as Params;

        /*
        const searchParams = (new URL(window.location.href)).searchParams;
        searchParams.set('person', this.searchTermPerson);
        searchParams.set('unit', this.searchTermUnit);
        const strParams = searchParams.toString();

        this.location.replaceState(SearchPageComponent.navRoute.url, strParams);
        */

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
            params
        };
        this.searching = true;
        this.http.get(url, options).subscribe( result => {
            if (result['count'] === 0) {
                const msg = new MsgDef($localize `Your query returned no records`, 'warn')
                this.snackBarRef = this.msgService.show(msg);
            } else if (result['count'] > 50) {
                const msg = new MsgDef($localize `Your query returns too many records, please refine your query`, 'warn')
                this.snackBarRef = this.msgService.show(msg);
                this.table.loadData([]);
            } else {
                this.table.loadData(result['data']);
            }
            this.searching = false;
        }, failure => {
            this.msgService.showError(failure, 'Searching');
        })
    }

    showTable() {

        const omcNameField = FormTextComponent.make('OMC', 'omcName');
        const nameField = FormTextComponent.make('Name', 'firstName', {calculateValue: o => Person.fullName(o as Person)} );
        const emailField = FormEmailComponent.make('Email', 'email');
        const phoneField = FormTextComponent.make('Telephone', 'phone');
        const roleField = FormTextComponent.make('Role', 'roleName');
        //const unitField = FormTextComponent.make('Unit', 'unit');
        const unitField = FormButtonComponent.makeNavDetailButton('Unit', 'unit', 'unitId', UnitPageComponent.navRoute, true);
        //const unitField = FormButtonComponent.makeLink('Unit', 'unit', '/units/${unitId};_forceTeam=${teamId}');

        const llAddressField = FormTextAreaComponent.make('Personal Address', 'personAddress');
        const unitAddressField = FormTextAreaComponent.make('Unit Address', 'unitAddress');
        const notesField = FieldMaker.notes({ formColumn: 4 });

        const fields = [ omcNameField, nameField, roleField, unitField, phoneField, emailField, llAddressField, notesField, unitAddressField]

        this.config = new FormConfig({
            navRoute: PersonPageComponent.navRoute,
            title: $localize`People`,
            help: $localize`The members, owners, residents, directors of the Owner Management Company`,
            fieldSet: new FieldSet({
                fields: fields,
            }),
            mode: 'list',
            forceTeamSwitchOnDrilldown: true
        });
    }

}
