/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { MyInjector } from 'src/app/app.module';
import { Person } from 'src/app/model/person';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { EditDialogComponent } from 'src/app/shared/dialogs/edit-dialog/edit-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FORM_ERRORS } from 'src/app/shared/form/form-error/form-error.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { MessageService } from 'src/app/shared/message.service';
import { required } from 'src/app/shared/validators';
import { AgentDataService } from '../../agent/agent-data.service';
import { RequestPersonService } from './RequestPerson.service';
import { UnitDetailsAction } from './UnitDetailsAction';
import { Role } from 'src/app/model/role';
import { RoleService } from 'src/app/pages/role-page/role.service';

export class PersonDetailsAction implements IFormAction {
    name = $localize`Person`;
    color: ActionColor = 'primary';
    show = true;
    icon = 'person';
    approvalNeeded = false;
    disabled = false;
    approvalText = $localize``;
    tipText: string;
    sr: ServiceRequest;
    config: FormConfig;

    defaultData: Person;

    private msgSvc = MyInjector.instance.get(MessageService);
    private dialog = MyInjector.instance.get(MatDialog);
    private agentDataSvc = MyInjector.instance.get(AgentDataService);

    constructor(private personField: PicklistField, private teamField: Field, private unitField: PicklistField) {
    }

    action(sr: ServiceRequest, config: FormConfig) {
        this.config = config;
        if (this.personField.control.value) {
            return this.editPerson();
        } else {
            return this.createPerson();
        }
    }

    setup(sr: ServiceRequest) {
        this.sr = sr;

        if (sr.unitId || sr.personId) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
        if (sr.personId) {
            this.name = $localize`Edit Person`;
            this.tipText = $localize `Click to view or edit edit person details`;
        } else {
            this.name = $localize`New Person`;
            this.tipText = $localize`Click to add a new person to this unit and grant them access to OMC`;
        }
    }

    editPerson() {
        const dialogRef = this.dialog.open(PersonPageComponent,
            {
                data: {
                    id: this.personField.control.value,
                    forceTeamId: this.teamField.control.value,
                    hideTabs: true
                }
            }
        );

        return dialogRef.afterClosed().pipe(map(person => {
            if (person) {
                if (this.agentDataSvc) {
                    this.agentDataSvc.updatePerson(person);
                }
                this.sr.person = person;
            }
            return this.sr;
        }));
    }

    addressField = FormTextAreaComponent.make('Address', 'address', {
        formColumn: 2, validators: [this.addressValidator.bind(this)]
    });
    addressRequired = true;

    addressValidator(ctl: AbstractControl) {
        if (this.addressRequired && !ctl.value) {
            return FORM_ERRORS.required.new();
        } else {
            return null;
        }
    }

    refreshRole(o) {
        if (o.owner && !o.resident) {
            this.addressRequired = true;
            this.addressField.enableControl();
        } else {
            this.addressRequired = false;
            this.addressField.disableControl();
        }
        this.addressField.control.updateValueAndValidity();
    }

    roleField = FormPicklistComponent.make('Role', 'roleId', 'role',
        { items: [], refreshes: [this.refreshRole.bind(this)] }, { validators: [required] }
    );

    getPersonFields() {

        const unitField = FormPicklistComponent.make('Unit', 'unitId', 'role',
            { items: [ this.sr.unit ] }, {disable: true, formColumn : 2}
        );

        const fields = [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.idHolder('requestId'),
            unitField,
            this.roleField,
            Person.getTitleField(),
            FormTextComponent.make('First Name', 'firstName'),
            FormTextComponent.make('Last Name', 'lastName', { validators: [required] }),
            FormTextComponent.make('Telephone', 'phone'),
            FormEmailComponent.make('Email', 'email'),
            this.addressField,
            FieldMaker.notes().override({ formColumn: 2 }),
        ];
        return fields;
    }

    newPerson() {
        const roleSvc = MyInjector.instance.get(RoleService);
        return roleSvc.get(false, null, this.sr.teamId).pipe( map( (urs: Role[]) => {
            this.roleField.setPicklistItems(urs);

            let role: Role;
            let person: Person;

            if (typeof this.defaultData === 'object') {
                const owner = this.defaultData['unit']?.['owner'] ? true : false;
                const resident = this.defaultData['unit']?.['resident'] ? true : false;
                if (owner && !resident) {
                    role = urs.find( o => o.globalRoleId === Role.ROLE.OWNER.id );
                } else if (owner && resident) {
                    role = urs.find(o => o.globalRoleId === Role.ROLE.OWNER_OCCUPIER.id);
                } else if (resident) {
                    role = urs.find(o => o.globalRoleId === Role.ROLE.RESIDENT.id);
                }

                person = this.defaultData;
                person.notes = "Added by request " + this.sr.refNr;
            } else {
                person = new Person();
            }

            person['unitId'] = this.sr.unitId;
            person['requestId'] = this.sr.id;

            if (role) {
                person['roleId'] = role?.id;
                // Mark Dirty to enable save button... Needs to happen after controls initialised though...
                window.setTimeout(() => this.roleField.control.markAsDirty(), 200);
                this.refreshRole(role);
            }

            return person;
        })); //(urs => this.roleField.setPicklistItems(urs));
    }
/*
    newPerson() {

        const roleSvc = MyInjector.instance.get(UnitRoleService);
        roleSvc.get(false, null, this.sr.teamId).subscribe(urs => this.roleField.setPicklistItems(urs));

        const person = this.defaultData ? this.defaultData : new Person();
        person['unitId'] = this.sr.unitId;
        person['requestId'] = this.sr.id;
        return of(person);
    }
*/
    createPerson() {

        const svc = MyInjector.instance.get(RequestPersonService);
        const isNarrow = MyInjector.instance.get(IsNarrowService);

        const fields = this.getPersonFields();

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Person`,
                fieldSet: new FieldSet(
                    {
                        fields: fields,
                        formLayout: [{ cells: [{ width: '50%' }, {width: '50%'}] }]
                    }
                ),
                mode: 'new',
                objectFactory: this.newPerson.bind(this),
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: svc,
                    forceTeamId: this.sr.teamId,
                    width: isNarrow.screenWidth > 800 ? 800 : isNarrow.screenWidth * .9,
                    hideTabs: true
                }
            }
        );

        return dialogRef.afterClosed().pipe( map( request => {
            if (request) {
                // If person was added, may need to modify the unit to cancel last owner or such like...
                const udc = new UnitDetailsAction(this.unitField, this.teamField);
                udc.setup(this.sr);
                console.warn('Created and subscribed');
                // this.sr, this.config
                udc.action().subscribe();
            }
            return request;
        }));
        /*
        dialogRef.afterClosed().pipe(first()).pipe<Action>(map(o => {
            return o;
        }));
        */
    }
}
