/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { BankAccount, BankAnalysis, BankItem, BankRule } from 'src/app/model/bankAccount';
import { of } from 'rxjs';
import { BCode } from 'src/app/model/bcode';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { maxChars, minChars, required } from 'src/app/shared/validators';
import { BCodeService } from '../../budget/bcode.service';
import { BankAccountService } from '../bank-account.service';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { LinkBankAccountAction } from './link-bank-account-action';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GmtAgoPipe } from 'src/app/shared/pipes/gmt-ago.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GoCardlessAccess, GoCardlessOpenBankingService } from './gocardless-open-banking-service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { OmcAgentService } from '../../user/omc-agent-service';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { Field } from 'src/app/shared/field/Field';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { BankTransferAction } from './bank-transfer-action';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { BankChargesAction } from './bank-charges-action';
import { ManualReconciliationAction } from './manual-reconciliation/manual-reconciliation-action';
import { BankPageComponent } from '../bank-page/bank-page.component';
import { Txn } from 'src/app/model/txn';

@Component({
    selector: 'app-bank-account-page',
    templateUrl: './bank-account-page.component.html',
    styleUrls: ['./bank-account-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent, MatButtonModule, MatIconModule, GmtAgoPipe, MatTooltipModule,
        NgTemplateOutlet, MatProgressSpinnerModule]
})
export class BankAccountPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('bank-accounts', BankAccountPageComponent, 'account_balance');

    gocAccesses: GoCardlessAccess[] = [];
    hasGocAccesses = 0;

    totalBanks = 0;
    linkedBanks = 0;

    agentField: PicklistField = FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
        { items: [] },
        { validators: [required], formColumn: 2 }
    );

    unLinked = $localize`This account does not match the IBAN of any bank account you have setup.
    You should setup a bank account to match this IBAN, or update existing accounts to match,
    or revoke the access as it cannot currently be used.
    `

    analysisGrid = new GridField({
        field:
        {
            label: 'Cashflow', value: 'txnAnalysis', sendServer: false,
            visible: Field.formOnly, formRow: 2
        },
        rowFactory: this.balanceGridRow.bind(this)
    });

    statementGrid = new GridField({
        field:
        {
            label: 'Reconciliations', value: 'statements', sendServer: false,
            visible: Field.formOnly, formRow: 2
        },
        rowFactory: this.statementGridRow.bind(this)
    });

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc);

    rulesGrid = new GridField({
        field:
        {
            label: 'Rules', value: 'rules', sendServer:false,
            visible: Field.formOnly, formRow: 2
        },
        rowFactory: () => [
            FieldMaker.id(),
            FieldMaker.rev(),
            FormTextComponent.make('Field', 'field', { readonly: true }),
            FormTextComponent.make('Matches', 'matches', { readonly: true }),
            FormTextComponent.make('Applied to', 'appliedTo', { readonly: true,
                calculateValue: (o: BankRule) => o.unitId ? o.unitName : o.supplierName}),
            FieldMaker.notes(),
            FormCheckboxComponent.make('Active', 'isActive'),
            FormNumberComponent.make('Used', 'used'),
            FieldMaker.spacer()
        ]
    });

    config = new FormConfig({
        navRoute: BankAccountPageComponent.navRoute,
        title: $localize`Bank`,
        help: $localize`Bank accounts`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormTextComponent.make('Name', 'name', { validators: [required, maxChars(20)] }),
                FormTextComponent.make('IBAN', 'IBAN', { formColumn: 1, validators: [minChars(16), maxChars(30)] }),
                FormTextComponent.make('BIC', 'BIC', { formColumn: 1, validators: [maxChars(11), minChars(8)] }),

                this.agentField,
                FormDateComponent.make('Last Reconciliation Date', 'lastRecDate', {disable: true, formColumn: 2}),
                FormNumberComponent.makeCurrency('Last Reconciled Balance', 'lastRecBalance', {disable: true, formColumn: 2}),

                FormCheckboxComponent.make('Linked', 'linkedGoc', {
                    calculateValue: (o: BankAccount) => o.gocId,
                    hint: 'Has this account been linked so data can be reconciled',
                    formColumn: 3,
                    disable: true
                }),
                FormDateTimeComponent.make('Balance as at', 'gocLastPull', { disable: true, formColumn: 3 }),
                FormNumberComponent.makeCurrency('Last Balance', 'lastBalance', { disable: true, formColumn: 3 }),

                FormCheckboxComponent.make('default', 'defaultAcct', {
                    hint: 'Is this the default account should be selected when entering payments',
                    formColumn: 4
                }),
                FormPicklistComponent.make('Asset Budget Code', 'bCodeId', 'bCode',
                    { service: this.bCodeSvc, serviceFilter: new HttpParams().set('typeId', BCode.TYPE.ASSET.id) },
                    { formColumn: 4, validators: [required] }),
                FormPicklistComponent.make('Charges Budget Code', 'chargesBCodeId', null,
                    { service: this.bCodeSvc, serviceFilter: new HttpParams().set('typeId', BCode.TYPE.EXPENSE.id) },
                    { formColumn: 4, validators: [required] }),


                // Incomplete: FormCheckboxComponent.make('Stripe Account', 'defaultPayz', {formColumn: 4}),
                FieldMaker.notes({ formColumn: 5 }),
                this.analysisGrid,
                this.statementGrid,
                this.rulesGrid
            ],
            formLayout: [
                { cells: [{ width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }] },
                { cells: [{ colspan: 5, pageTab: 'yes' }] },
            ]
        }),
        service: this.dataSvc,
        mode: 'list',
        actions: [ new BankChargesAction(), new BankTransferAction(), new ManualReconciliationAction() ],
        objectFactory: this.newBankAccount.bind(this),
        beforeEdit: this.beforeEdit.bind(this),
        beforeList: this.beforeList.bind(this),
        tabFields: [this.attachmentGrid],
        newOptions: [
            { name: $localize`New ${Txn.TYPE.BANK_IN.name}`, basePath: `/${Txn.TYPE.BANK_IN.code}/NEW`, params: {} },
            { name: $localize`New ${Txn.TYPE.BANK_OUT.name}`, basePath: `/${Txn.TYPE.BANK_OUT.code}/NEW`, params: {} },
            { name: $localize`New Bank Account`, basePath: `/bank-accounts/NEW`, params: {} },
        ],
    });

    constructor(public dataSvc: BankAccountService, public bCodeSvc: BCodeService,
        private gocobSvc: GoCardlessOpenBankingService, private cds: ConfirmDialogService,
        omcAgentSvc: OmcAgentService) {
        super();
        omcAgentSvc.get(true).subscribe(os => this.agentField.setPicklistItems(os));
        //this.getSampleData().subscribe ( d => this.gocAccesses = d as unknown as GoCardlessAccess[]);
    }

    statementGridRow(o: BankItem) {
        const openingBal = FormNumberComponent.makeCurrency("Opening Balance", 'data.openingBal', {
            readonly: true, sendServer: false
        });
        if (o && o.data.openingBal < 0.00) {
            openingBal.cellOpts.style = 'color:red; white-space:nowrap; text-align: right';
        }

        const recFromField = FormDateComponent.make('From Date', 'data.fromDate', {
            readonly: true, sendServer: false
        });

        const closingBal = FormNumberComponent.makeCurrency("Closing Balance", 'data.closingBal', {
            readonly: true, sendServer: false
        });
        if (o && o.data.closingBal < 0) {
            closingBal.cellOpts.style = 'color:red; white-space:nowrap; text-align: right;';
        }

        const nettValue = FormNumberComponent.makeCurrency("Transaction Value", 'txnValue', {
            calculateValue: (o: BankItem) => o.data.closingBal - o.data.openingBal,
            readonly: true
        });
        if (o && ((o.data.closingBal - o.data.openingBal) < 0)) {
            nettValue.cellOpts.style = 'color:red; white-space:nowrap; text-align: right;';
        }
        const recDateField = FormDateComponent.make('Reconciliation Date', 'data.toDate', {
            readonly: true, sendServer: false
        });
        if (o && o.data.toDate < o.data.fromDate) {
            recFromField.cellOpts.style = 'color:red';
            recDateField.cellOpts.style = 'color:red';
        }

        return [
            recFromField,
            openingBal,
            nettValue,
            recDateField,
            closingBal,
            FormButtonComponent.makeNavButton('Items',
                BankPageComponent.navRoute, (o: BankItem) => ({
                    _sort: 'txnDate', _forceTeamId: o.teamId,
                    bankAccountId: o.bankId,
                    txnDate: `><${o?.data.fromDate}:${o?.data.toDate}`,
                }),
                { calculateValue: () => 'Transactions' }
            ),
            FieldMaker.spacer()
        ]
    }

    balanceGridRow(o: BankAnalysis) {
        const openingBal = FormNumberComponent.makeCurrency("Opening Balance", 'openBalance', { readonly: true });
        if (o && o.openBalance < 0.00) {
            openingBal.cellOpts.style = 'color:red; white-space:nowrap; text-align: right';
        }

        const closingBal = FormNumberComponent.makeCurrency("Balance", 'balance', { readonly: true });
        if (o && o.balance < 0) {
            closingBal.cellOpts.style = 'color:red; white-space:nowrap; text-align: right;';
        }

        return [
            FormTextComponent.make('Period', 'periodName', { readonly: true }),
            FormDateComponent.make('Start Date', 'startDate', { readonly: true }),
            openingBal,
            FormNumberComponent.makeCurrency("Money In", 'debits', { readonly: true }),
            FormNumberComponent.makeCurrency("Money Out", 'credits', { readonly: true }),
            FormDateComponent.make('End Date', 'endDate', { readonly: true }),
            closingBal,
            FormButtonComponent.makeNavButton('Items',
                BankPageComponent.navRoute, (o: BankAnalysis) => ({
                    txnPeriodId: o.txnPeriodId, _sort: 'txnDate', _forceTeamId: o.teamId, bankAccountId: o.bankAccountId
                }),
                { value: 'txns' }
            ),
            FieldMaker.spacer()
        ]
    }

    beforeEdit(ba: BankAccount) {
        let runningBalance = 0;
        ba.txnAnalysis.slice().reverse().forEach(bai => {
            bai.openBalance = runningBalance;
            bai.debits = +bai.debits;
            bai.credits = +bai.credits;
            runningBalance = runningBalance + bai.debits - bai.credits;
            bai.balance = runningBalance;
        })
        return ba;
    }

    beforeList(bas: BankAccount[]) {
        this.linkedBanks = 0;
        this.totalBanks = 0;
        bas.forEach(ba => {
            this.totalBanks += 1;
            if (ba.gocId) {
                this.linkedBanks += 1;
            }
        })
        return bas;
    }

    newBankAccount() {
        const ba = new BankAccount();
        const agts = this.agentField.picklist.items;
        if (agts.length === 1) {
            ba.agentTeamId = agts[0].id;
        }
        return of(ba);
    }

    linkBank() {
        const linker = new LinkBankAccountAction();
        linker.setup({} as BankAccount);
        linker.action();
    }
    checkAccesses() {
        this.hasGocAccesses = -1;
        this.gocobSvc.getAccesses().subscribe(response => {
            this.gocAccesses = response;
            this.hasGocAccesses = 1;
        });
    }

    removeAccess(goca: GoCardlessAccess) {
        const title = $localize`Remove Access`;
        const msg = $localize`Do you wish to remove Open Banking Access?
        You will no longer be able to import transactions for related accounts`
        const doit = () => {
            this.gocobSvc.removeAcess(goca).subscribe(response => {
                if (response) {
                    const idx = this.gocAccesses.findIndex(o => o.id === goca.id);
                    this.gocAccesses.splice(idx, 1);
                }
            })
        }
        this.cds.open(title, msg, doit);
    }
}
