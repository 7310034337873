/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';
import { catchError, map } from 'rxjs/operators';
import { MailItem } from 'src/app/model/mailMerge';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + Txn.TYPE.INVOICE.code;
    protected cache: Txn[];
    protected typeString = this.ajaxPath + Txn.TYPE.INVOICE.name;

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getPDF(txn: Txn, mailIt = false, personId = null) {
        const url = `${this.baseUrl}/${txn.id}/pdf`;
        if (!mailIt) {
            this.downloadFile(url, txn.teamId, 'application/pdf');
        } else {
            return this.http.post(url, {personId}, this.getSimpleOptions(txn.teamId)).pipe( map(response => {
                this.messageService.show('PDF Sent');
                return response;
            }), catchError(this.handleOneError<MailItem>('getAudit' + this.typeString, null)));
        }
    }

}
