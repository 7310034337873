/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Person } from 'src/app/model/person';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { catchError, map } from 'rxjs/operators';
import { MailItem } from 'src/app/model/mailMerge';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'people';
  protected cache: Person[];
  protected typeString = 'Person';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }

  sendMail(p: Person, subject: string, content: string) : Observable<MailItem>{
    const url = this.baseUrl + '/sendMail/' + p.id;
    return this.http.post<MailItem>(url, {subject, content}, this.getSimpleOptions(p.teamId)).pipe(
        map( x => {
            return x;
        }),
        catchError((error) => {
            this.messageService.showError(error, 'Sending Mail');
            return of(null);
        })
    )
  }
}
