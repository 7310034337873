import { CurrencyPipe, DatePipe, NgClass, NgTemplateOutlet, PercentPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Billing, BillingItem } from 'src/app/model/billing';
import { TextEditComponent } from 'src/app/shared/simpleFields/text-edit/text-edit.component';
import { BillingService } from '../../billing.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { DateTimeItemComponent } from 'src/app/shared/simpleFields/date-time-item/date-time-item.component';
import { CurrencyItemComponent } from 'src/app/shared/simpleFields/currency-item/currency-item.component';
import { NavRoute } from 'src/app/shared/NavRoute';
import { NavigateItemComponent } from 'src/app/shared/simpleFields/navigate-item/navigate-item.component';
import { ScheduleService } from '../../schedule.service';
import { Unit } from 'src/app/model/unit';
import { combineLatest } from 'rxjs';
import { BCodeService } from '../../bcode.service';
import { MyUnitService } from 'src/app/modules/unit/my-unit.service';
import { Schedule } from 'src/app/model/schedule';
import { BCode } from 'src/app/model/bcode';
import { concatMap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ShowUnitBillingComponent } from '../show-unit-billing/show-unit-billing.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InvoicingActionComponent } from '../invoicing-action/invoicing-action-component';
import { UserNameComponent } from 'src/app/modules/user/user-name/user-name.component';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { MailMerge } from 'src/app/model/mailMerge';
import { DateHelper } from 'src/app/shared/dateHelper';
import { Cycle } from 'src/app/model/cycle';
import { BudgetingPageComponent } from '../../budgeting-page/budgeting-page.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { BCodePageComponent } from '../../b-code-page/b-code-page.component';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { CtlHolderComponent } from "../../../../shared/form/ctl-holder/ctl-holder.component";
import { UnitPageComponent } from 'src/app/modules/unit/unit-page/unit-page.component';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { ArPageComponent } from 'src/app/modules/txn/ar-page/ar-page.component';

@Component({
  selector: 'app-billing-detail',
  standalone: true,
  imports: [TextEditComponent, MatCardModule, DateTimeItemComponent, RouterLink,
    MatButtonModule, MatIconModule, CurrencyItemComponent, NavigateItemComponent, NgClass, NgTemplateOutlet,
    CurrencyPipe, MatTooltipModule, UserNameComponent, FromNowPipe, DatePipe, PercentPipe, CtlHolderComponent],
  templateUrl: './billing-detail.component.html',
  styleUrl: './billing-detail.component.scss'
})
export class BillingDetailComponent {
    billing: Billing = new Billing();
    billingSvc = inject(BillingService)
    activatedRoute = inject(ActivatedRoute);
    scheduleSvc = inject(ScheduleService);
    bCodeSvc = inject(BCodeService);
    myUnitSvc = inject(MyUnitService);
    dialog = inject(MatDialog);
    cds = inject(ConfirmDialogService);

    units: Unit[] = [];
    schedules: Schedule[] = [];
    bCodes: BCode[]= [];

    daysSinceStart = 0;
    pctExpected = 0;
    pctAchieved = 0;
    collectionStatus : 'good' | 'bad' | 'mediocre' = 'good';
    toBeBilled = false;
    toBeApproved = false;
    beingBilled = false;

    get invNavRoute() : NavRoute { return ArPageComponent.navRoute}

    get budgetNav(): string {
        return BudgetingPageComponent.navRoute.getIdUrl(this.billing.id);
    }

    constructor() {

        combineLatest([
            this.scheduleSvc.get(true),
            this.bCodeSvc.get(true),
            this.myUnitSvc.get(),
            this.activatedRoute.params.pipe( concatMap(params => this.billingSvc.getOne(params.itemId, null)))
        ]).subscribe(([schedules, bcodes, units, billing]) => {
            this.units = units as Unit[];
            this.schedules = (schedules as Schedule[]);
            this.bCodes = (bcodes as BCode[]);
            if (billing) {
                this.loadBilling(billing);
            }
        });
    }

    getBCodeUrl(bCodeId) {
        return BCodePageComponent.navRoute.getIdUrl(bCodeId);
    }

    loadBilling(billing: Billing = null) {
        if (billing) this.billing = billing;
        if (!this.billing) return;

        this.toBeApproved = false;
        this.toBeBilled = false;
        this.beingBilled = false
        if (this.billing.statusId === Cycle.statusIds.PROPOSED) {
            this.toBeApproved = true;
        } else if (this.billing.statusId === Cycle.statusIds.ACCEPTED) {
            this.toBeBilled = true;
        } else if (this.billing.statusId === Cycle.statusIds.BILLING) {
            this.beingBilled = true;
        }
        this.billing.mailMerges.forEach (mm => {
            if (mm.attachments?.length > 0) {
                mm['attachField'] = AttachmentGridComponent.make(this.billingSvc);
                mm['attachField'].override({showSimple: true, readonly: true, showGrid: false});
                mm['attachField'].setupControl();
                mm['attachField'].setValue(mm, true);
            }
        })
        for (const bi of this.billing.items) {
            const bibc = this.bCodes.find(o => o.id === bi.bCodeId);
            if (bibc) {
                bi.bCodeName = bibc.name;
            }
        }
        this.calcUnitAmounts();
    }

    getUnitsLink(mm: MailMerge) {
        return UnitPageComponent.navRoute.getListRouterLink({ mergeId: mm.id });
    }

    getPeopleLink(mm: MailMerge) {
        return PersonPageComponent.navRoute.getListRouterLink({ mergeId: mm.id });
    }

    calcCollectedPcts() {
        this.daysSinceStart = DateHelper.isoDaysSince(this.billing.cycle.from);
        this.pctExpected = this.daysSinceStart > 365 ? 1 : (this.daysSinceStart <= 0 ? 0 : this.daysSinceStart / 365);
        const expected = this.billing.billedTotal * this.pctExpected
        const collected = this.billing.billedTotal - this.billing.outstandingTotal;
        this.pctAchieved = collected / this.billing.billedTotal;
        if ( collected > (expected * .99)) {
            this.collectionStatus = 'good'
        } else if (collected > (expected * .8)){
            this.collectionStatus = 'mediocre';
        } else {
            this.collectionStatus = 'bad';
        }
    }
    calcUnitAmounts() {
        this.calcCollectedPcts();
        this.billing.status = Cycle.statuses.find( o => o.id === this.billing.statusId);
        this.billing['toBill'] = 0;
        this.billing['wasBilled'] = 0;
        for (const bi of this.billing.items) {
            this.billing['toBill'] += bi.total;
            this.billing['wasBilled'] += bi.billed;
        }
        for (const u of this.units) {
            let uTotal = 0;
            //for (const s of this.schedules) {
            for (const bi of this.billing.items) {
                const uPortion = this.getAmount(u, bi);
                u[this.getKey(bi)] = uPortion;
                uTotal += uPortion;
            }
            for (const bi of this.billing.items) {
                bi['unit-' + u.id] = this.getAmount(u, bi)
            }
            u['total'] = uTotal;

            this.billing['total-' + u.id] = uTotal;
        }
    }

    showUnit() {
        const dialogRef = this.dialog.open(ShowUnitBillingComponent);
        return dialogRef.afterClosed().subscribe((u: Unit) => {
            if (u) {
                this.units.push(u);
                this.loadBilling();
            }
        });
    }
    addCommunication(mailMerge:MailMerge = null) {
        this.dialog.open(InvoicingActionComponent, {data: {billing: this.billing, mailMerge}}).afterClosed().subscribe ( b => {
            if (b) {
                this.loadBilling(b);
            }
        });
    }

    getKey(bi: BillingItem) {
        return (bi.scheduleId as string) + (bi.bCodeId as string);
    }

    getAmount(unit: Unit, charge: BillingItem) {
        //const charge = billing.items.find(s => s.scheduleId === schedule.id);
        if (charge) {
            return charge.total * this.unitPercent(unit, charge);
        } else {
            console.warn('No charge for schedule', { unit, charge });
            return 0;
        }
    }

    unitPercent(unit: Unit, bi: BillingItem) {
        const portion = unit.portions.find(p => p.scheduleId === bi.scheduleId);
        return portion ? portion.percent : 0;
    }

    billOut() {
        const title = $localize`Billout Now`;
        const msg = $localize`Issue service charges to members now WARNING THIS CANNOT BE UNDONE?`;
        this.cds.open(title,msg, () => {
            this.billingSvc.billout(this.billing).subscribe( x => {
                if (x) {
                    this.loadBilling(x);
                }
            })
        });
    }
}
