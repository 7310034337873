/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { inject, Injectable } from "@angular/core";
import { forkJoin, Observable, throwError } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";
import { AbstractObjectList } from "src/app/model/abstract-object";
import { User } from "src/app/model/user";
import { CurrentUserService } from "../../user/current-user.service";
import { BatchType, ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { GeneralParser } from "./GeneralParserService";
import { ParseOptionBase } from "./ParseOptionBase";
import { ParseOptionBaseMapped } from "./ParseOptionBaseMapped";
import { POBValidators } from "./POBValidators";
import { Unit } from "src/app/model/unit";
import { UnitService } from "../../unit/unit.service";
import { UnitTypeService } from "../../unit/unit-type-page/unit-type.service";
import { UnitCategoryService } from "../../unit/unit-type-page/unit-category.service";
import { ImportRow } from "../ImportRow";
import { UnitTypePageComponent } from "../../unit/unit-type-page/unit-type-page.component";
import { AreaService } from "../../unit/area.service";
import { AreaPageComponent } from "../../unit/area/area-page.component";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { MyInjector } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { Area } from "src/app/model/area";
import { Core } from "src/app/model/core";
import { UnitType } from "src/app/model/unit-type";
import { ScheduleService } from "../../budget/schedule.service";
import { Schedule } from "src/app/model/schedule";

@Injectable({
    providedIn: 'root'
})
export class UnitParserService implements ImportParser {

    batchType: BatchType = 'Unit';

    gps = new GeneralParser();
    unitSvc = inject(UnitService);
    typeSvc = inject(UnitTypeService);
    catSvc = inject(UnitCategoryService);
    currentUserSvc = inject(CurrentUserService);
    areaSvc = inject(AreaService);
    scheduleSvc = inject(ScheduleService);
    cds = inject(ConfirmDialogService);

    loaded = false;

    units : Unit[] = [];

    importDoc: ImportDoc;
    currentUser: User;

    unitIdPOB = new ParseOptionBase('UnitID', 'uniqueID', [POBValidators.requiredPOB()]);
    unitNamePOB = new ParseOptionBase('Unit', 'name', [POBValidators.requiredPOB()]);
    unitAddress = new ParseOptionBase('Unit Address', 'address', [POBValidators.requiredPOB(), POBValidators.noNewLines()]);
    unitTypePOB = new ParseOptionBaseMapped('Type', 'typeId');
    unitCategoryPOB = new ParseOptionBaseMapped('Category', 'categoryId').allowNull();

    unitSizePOB = new ParseOptionBase('Unit Size', 'size');
    bedroomsPOB = new ParseOptionBase('Bedrooms', 'bedrooms');
    floorPOB = new ParseOptionBase('Floor', 'floor');
    carSpacesPOB = new ParseOptionBase('Car Spaces', 'carSpaces');
    NrCarSpacesPOB = new ParseOptionBase('Number of Car Spaces', 'numberCarSpaces');

    unitAreaPOB = new ParseOptionBaseMapped('Area', 'areaId');
    unitCorePOB = new ParseOptionBaseMapped('Core', 'coreId').allowNull();
    unitNotes = new ParseOptionBase('Unit Notes', 'notes');

    availableImportItems = [
        this.unitNamePOB,
        this.unitAddress,
        this.unitTypePOB,
        this.unitCategoryPOB,
        this.unitSizePOB,
        this.bedroomsPOB,
        this.floorPOB,
        this.carSpacesPOB,
        this.NrCarSpacesPOB,
        this.unitAreaPOB,
        this.unitCorePOB,
        this.unitNotes,
    ];

    setUp() {
        this.importDoc = new ImportDoc();
        if (this.loaded) {
            this.cds.alert('You need to reload the page', 'Page reload needed', () => {
                window.location.reload();
            })
        }

        return forkJoin({
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            types: this.typeSvc.get<UnitType>(true),
            cats: this.catSvc.get(true),
            areas: this.areaSvc.get<Area>(true),
            units: this.unitSvc.get(true),
            schedules: this.scheduleSvc.get<Schedule>(false),
        }).pipe(map(result => {
            this.currentUser = result.currentUser;
            this.unitTypePOB.setOptions(result.types, this.importDoc);
            this.unitTypePOB.setCreator( UnitTypePageComponent );
            this.unitAreaPOB.setOptions(result.areas, this.importDoc);
            this.unitAreaPOB.setCreator( AreaPageComponent );
            this.unitCorePOB.setOptions(this.getCores(result.areas), this.importDoc)
            this.unitCategoryPOB.setOptions(result.cats, this.importDoc);
            this.units = result.units as Unit[];
            this.setupSchedules(result.schedules);
            this.loaded = true;
            return true;
        }));
    }

    setupSchedules(scheds: Schedule[]) {
        if (scheds) {
            scheds.forEach( s => {
                if (s.chargeTypeId === Schedule.chargeId.custom) {
                    const schedPct = new ParseOptionBase(s.name + '%', 'schedulePortion' + s.id);
                    this.availableImportItems.push(schedPct);
                }
            })
        }
    }
    getCores(areas: Area[]) {
        const cores : Core[] = [];
        areas.forEach( a => {
            a.cores.forEach ( c => cores.push(c))
        })
        return cores;
    }

    parseRows(rows: unknown[][]): ImportDoc {

        this.gps.availableImportItems = this.availableImportItems;
        this.gps.parseRows(rows, this.importDoc);

        return this.importDoc;
    }

    validateData(postItems: Unit[]) {
        const dataRows = this.importDoc.getGeneralRows();
        for (let i = 0; i < postItems.length; i++) {
            const line = dataRows[i];
            const data = postItems[i] as Unit;
            if (data.coreId && data.areaId) {
                const area = this.unitAreaPOB.getOption<Area>(data.areaId);
                console.log({data, area});
                if (!area.cores.find( c => c.areaId === area.id)) {
                    line.addError('The core does not belong to the area specified');
                }
            } else if (data.coreId && !data.areaId) {
                line.addError('Area is mandatory when core is specified');
            }
            if (data.typeId && data.categoryId) {
                const unitType = this.unitTypePOB.getOption<UnitType>(data.typeId);
                if (!unitType.categories.find( c => c.typeId === unitType.id)) {
                    line.addError('The category does not belong to the specified type');
                }
            } else if (data.categoryId && !data.typeId) {
                line.addError('Type is mandatory when category is specified');
            }
        }
        this.importDoc.finalise();
    }

    postToServer(): Observable<AbstractObjectList> {
        const idColumn = this.unitIdPOB.getMappedColumn();
        const postItems = this.gps.getItemsToPost((row: ImportRow) => {
            const u = {id: null, revision: null};
            const exists = this.units.find( u => u.name === row.getValue(this.unitNamePOB.getMappedColumn()));
            if (exists) {
                u.id = exists.id;
                u.revision = exists.revision;
            }
            return u;
        });

        this.validateData(postItems as Unit[]);
        if (this.importDoc.hasErrors()) {
            console.log(postItems);
            return throwError(new Error('Validation Errors'));
        }

        let existingUnits = 0;
        let existingNames = '';
        let newUnits = 0;
        let newNames = '';

        this.importDoc.getGeneralRows().forEach(row => {
            const unitName = row.getValue(this.unitNamePOB.getMappedColumn())
            const exists = this.units.find(u => u.name === unitName);
            if (exists) {
                existingUnits += 1;
                existingNames += unitName + ", "
            } else {
                newUnits += 1
                newNames += unitName + ", "
            }
        })
        let msg = `Will import ${newUnits} new units and update ${existingUnits} existing units. `;
        if (existingUnits > 0 && existingUnits < newUnits) {
            msg += "Existing units are " + existingNames;
        } else if (newUnits > 0 && newUnits < existingUnits) {
            msg += "New units are " + newNames;
        }

        const choice = {
            title: 'Confirm Import',
            msg,
            options: [{name: 'OK', returns: true}]
        }

        const postData = {
            type: this.batchType,
            fields: this.gps.getFieldList(),
            newUnits,
            existingUnits,
            items: postItems
        }

        return this.cds.openChoice(choice).pipe(concatMap( proceed => {
            if (proceed) {
                //return this.unitSvc.postItems(postItems);
                const url = AbstractHttpService.ajaxPath + 'importFile/' + this.batchType;
                const http = MyInjector.instance.get(HttpClient);
                return http.post<AbstractObjectList>(url, postData);
            } else {
                this.cds.openConfirm("Operation Cancelled", "No data will be imported");
            }
        }));
        //return of({itemList: []});
    }
}
