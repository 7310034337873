/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PicklistField, PicklistOptions } from '../../field/PicklistField';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

export class AppPicklistControl extends AppFormControl {
    field: PicklistField;
}

@Component({
    selector: 'app-form-picklist',
    templateUrl: './form-picklist.component.html',
    styleUrls: ['./form-picklist.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatIconModule, MatTooltipModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule, MatInputModule, FormErrorComponent, NgClass]
})
export class FormPicklistComponent {
    @Input() control: AppPicklistControl;
    @Input() on: ControlOn = 'form';

    public static make(label: string, valueId: string, valueObjectName: string,
        picklist: Partial<PicklistOptions>, options: Partial<Field> = {}): PicklistField {

        const field = new PicklistField({ label, value: valueId }, options);
        field.picklist.valueObjectName = valueObjectName;
        field.formControlFactory = FormPicklistComponent.createComponent;
        field.picklist = { ...field.picklist, ...picklist };

        if (field.picklist.service) {
            let serviceFilter = field.picklist.serviceFilter;
            let useCache = true;
            if (!serviceFilter) {
                serviceFilter = null;
            } else {
                useCache = false; // cannot cache with filter, consider removing option
            }
            field.picklist.service.get(useCache, serviceFilter).subscribe((data) => {
                field.picklist.items = data;
                if (field.picklist.allowSelectNone) {
                    field.allowEmpty();
                }
            });
        } else {
            if (picklist.allowSelectNone) {
                field.allowEmpty();
            }
        }

        return field;
    }

    constructor(private router: Router) {
    }
    public static createComponent(vcr: ViewContainerRef, ctl: AppPicklistControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormPicklistComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    itemChanges(change) {
        //this.control.setValue(change.value.id, {emitEvent: false});
        const pl = this.control.field.picklist;
        const changeObject = pl.items.find(o => pl.optionValue(o) === change.value);
        pl.valueObject = changeObject;
        pl.refreshes.forEach(fldName => {
            if (typeof fldName === 'string') {
                const ctl = (this.control.parent as UntypedFormGroup).get(fldName) as AppFormControl;
                if (ctl) {
                    ctl.field.refresh(changeObject as AbstractObject, ctl);
                } else {
                    console.warn('No control [' + fldName + '] to refresh', this.control);
                }
            } else if ((typeof fldName === 'function')) {
                fldName(changeObject);
            } else {
                console.error('Do not know how to refresh ', { fldName, change, control: this.control, comp: this })
            }
        });
    }

    public matchById(option, value) {
        return option === value;
        /* return option && option.id ? option.id === value : false; */
        /*
        if (typeof value === 'number' && typeof option === 'object' ) {
          return option && option.id ? option.id === value : false;
        } else if ( (typeof value === 'string' || typeof value === 'number') && typeof value === typeof option) {
          return option === value;
        } else {
          return option && value ?  option.id === value.id : false;
        }
        */
    }

    viewDetails($event) {
        console.log('View Details', this, $event);
        $event.stopPropagation();
        if (this.control.field.picklist.detailView) {
            const route = this.control.field.picklist.detailView.replace('${value}', this.control.value);
            this.router.navigate([route]);
        } else {
            console.warn('No detail view configured, should not be visible');
        }
    }
}
