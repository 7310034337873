<div class="loginPage basePage">
  @if (showLoading) {
    <div class="login-spinner">
      <mat-spinner></mat-spinner>
    </div>
  }
  @if (!showLoading) {
    <div class="login-card">
      <ng-container *ngTemplateOutlet="accordionTemplate"></ng-container>
      <p class="disclaimer">
        By clicking any of the buttons above, you acknowledge that you have read,
        understood and agree to OurOMC.ie's <a href="/tos" (click)="termsOfService()">terms of service</a>
        and <a href="/tos"  (click)="termsOfService()">privacy policy</a>.
      </p>
    </div>
  }
</div>
<ng-template #accordionTemplate>
  <mat-accordion>
    <mat-expansion-panel
      (opened)="openSocial()" [expanded]="socialOpen">
      <mat-expansion-panel-header>
        <mat-panel-title>
          @if (!socialOpen) {
            <span class="title">Login</span>
          }
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="connectTemplate"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="openReset()" [expanded]="resetOpen">
      <mat-expansion-panel-header>
        <mat-panel-title>
          @if (!resetOpen) {
            <span class="title" >Register new user or Forgot Password</span>
          }
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="forgetTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
<ng-template #connectTemplate>
  <table width="100%">
    <tr>
      <td style="vertical-align: middle; text-align: center; padding-bottom: 20px">
        <button (click)="oAuthLogin('google')" mat-button
          style="margin-top: 10px; padding-top: 10px; padding-bottom: 10px; background:white">
          <img height="46px" width="191px" class="images" src="./assets/google_signin_logo.png" />
        </button>
      </td>
    </tr>
    <tr>
        <td style="vertical-align: middle; text-align: center; padding-bottom: 20px">
            <button (click)="oAuthLogin('microsoft')" mat-button
                style="margin-top: 10px; padding-top: 10px; padding-bottom: 10px; background:white">
                <img class="images" src="./assets/ms_signin_logo.png" />
            </button>
        </td>
    </tr>
    <tr>
      <td style="vertical-align: middle; text-align: center">
        <button mat-button (click)="oAuthLogin('github')" style="padding-top: 10px; padding-bottom: 10px; background:white">
          <img height="38px" class="images" src="./assets/github-cat-logo.jpg" />
          <img height="38px" class="images" src="./assets/GitHub_Logo.png" />
        </button>
      </td>
    </tr>
  </table>
  <p class="helpText" i18n>
    ---------- OR ----------
  </p>
  <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
</ng-template>
<ng-template #loginTemplate>
  <div class="loginForm">
    <form class="login-form">
      <mat-form-field class="login-full-width">
        <mat-label>Email address</mat-label>
        <input data-login-username [formControl]="usernameCtl" matInput
          name="username" required type="email"  (blur)="invalidUserOrPass = false">
      </mat-form-field>
      <mat-form-field class="login-full-width">
        <mat-label>Password</mat-label>
        <input data-login-password matInput  [formControl]="passwordCtl"
          [type]="hide ? 'password' : 'text'" name="password" (blur)="invalidUserOrPass = false" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </mat-form-field>
      @if (invalidUserOrPass) {
        <p i18n data-invalid-user-message>Invalid username or password</p>
      }
      @if(!usernameCtl.valid && usernameCtl.touched) {
        <p i18n data-invalid-user-message style="color:red">Use your email address to login</p>
      }
    </form>
    <mat-spinner diameter="20" [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
    @if (!showSpinner) {
      <button mat-raised-button data-login (click)="login()"
        [disabled]="!usernameCtl.valid || !passwordCtl.valid" color="primary">Login</button>
    }
  </div>
</ng-template>
<ng-template #forgetTemplate>
    <div class="loginForm">
        <form class="login-form">
            <mat-form-field class="login-full-width">
                <mat-label>Enter your email address</mat-label>
                <input data-reset-username [formControl]="emailCtl" matInput data-email-to-reset name="username"
                    required type="email">
            </mat-form-field>
            <p class="helpText" i18n>
                Do you really not have a google or microsoft account?
            </p>
            <p class="helpText" i18n>
                If you do, easier for everyone if you just click on login above, click google or microsoft, and voila,
                you are in!
                It will not give us access to anything other then confirming your name, email address and avatar.
                If you really want another password to remember, enter your email address,
                click the button and we'll do the needful :-)
            <p>
        </form>
        <p></p>
        @if (!registering) {
            <button mat-raised-button data-register (click)="register()" [disabled]="!emailCtl.valid"
                color="primary">Register / Reset Password</button>
        }
    </div>
</ng-template>
