/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { Field } from 'src/app/shared/field/Field';
import { UnitTypeService } from '../unit-type-page/unit-type.service';
import { AreaService } from '../area.service';
import { UnitCategoryService } from 'src/app/modules/unit/unit-type-page/unit-category.service';
import { GridField } from 'src/app/shared/grid/grid-field';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { Unit } from 'src/app/model/unit';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogOptions, PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { first, map } from 'rxjs/operators';
import { Person } from 'src/app/model/person';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { numericRange, required } from 'src/app/shared/validators';
import { UnitType } from 'src/app/model/unit-type';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { Txn } from 'src/app/model/txn';
import { Frequency } from 'src/app/model/frequency';
import { CycleService } from '../../budget/cycle.service';
import { Cycle } from 'src/app/model/cycle';
import { DateHelper } from 'src/app/shared/dateHelper';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PayzService } from '../../txn/payzService';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { MyUnitService } from '../my-unit.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { CurrentUserService } from '../../user/current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { uuid } from 'src/app/model/abstract-object';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { MyInjector } from 'src/app/app.module';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { Role } from 'src/app/model/role';
import { RoleService } from 'src/app/pages/role-page/role.service';
import { ActionGridComponent } from 'src/app/shared/action/action-grid.component';
import { AddActionFormAction } from 'src/app/shared/action/add-action-form-action';
import { MemoField } from 'src/app/shared/memos/MemoField';
import { AddMemoAction } from '../../crm/service-request-page/resolve-request/ResolveRequestAction';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { StatementPDFAction } from './statement-pdf-action';
import { UnitRoleSorter } from './unit-role-sorter';
import { MyUnitPageComponent } from '../my-unit-page/my-unit-page.component';
import { Area } from 'src/app/model/area';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';

@Component({
    selector: 'app-unit-page',
    templateUrl: './unit-page.component.html',
    styleUrls: ['./unit-page.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [FormPageComponent],
})

export class UnitPageComponent extends AbstractPageComponent implements OnInit {

    static readonly navRoute = new NavRoute('units', UnitPageComponent, 'account_balance').setViews(() => {
        return [
            {
                id: 'allUnits',
                name: 'All Units',
                filterFields: {
                    typeId: 'All',
                    areaId: 'All',
                    name: null,
                },
                sort: 'createdAt'
            }
        ];
    });
    forceTeamId : uuid = null;
    currentCycle: Cycle;
    mineOnly = true;
    roleList: Role[] = [];
    unitRoleList: Role[] = [];

    actionGrid = ActionGridComponent.make('Unit');

    editPerson(pur: PersonUnitRole, cell: AppFormControl) {
        const popDialogRef = this.dialog.open(PersonPageComponent,
            {
                data: {
                    id: pur.personId,
                    forceTeamId: pur.teamId,
                }
            }
        );
        popDialogRef.afterClosed().subscribe(person => {
            if (person) {
                pur.person = person;
                cell.getRow().refreshFrom(pur);
            }
        })
    }

    memoField = new MemoField(this.dataSvc);

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc);

    peopleRow(pur: PersonUnitRole) {
        const row = [];

        row.push(FieldMaker.primaryItem('ownerId', 'personId'));
        row.push(FieldMaker.id({ visible: Field.noShow }));
        row.push(FormTextComponent.make('PersonId', 'personId', { visible: Field.noShow }));

        //row.push(FormTextComponent.make('Person', 'person', { visible: Field.noShow }));

        row.push(FormPicklistComponent.make('Role', 'roleId', 'role', { items: this.unitRoleList, refreshes: ['roleId'] },
            { cellOpts: { minWidth: '10em' }, validators: [required], refresh: this.roleUpdate }
        ));

        //if (this.dialogRef) {
        row.push(FormButtonComponent.makeTextButton('Person Name', 'person', (cell) => {
            this.editPerson(pur, cell);
        }, {
            readonly: true,
            calculateValue: (o: PersonUnitRole) => Person.fullName(o.person)
        }));
        row.push(FormTextComponent.make('Phone', 'person.phone', { readonly: true, sendServer: false }));
        row.push(FormTextComponent.make('Email', 'person.email', { readonly: true, sendServer: false }));
        /* } else {
            row.push(FormButtonComponent.makeLink('Name', 'name', '/people/${personId}', {
                calculateValue: (o: PersonUnitRole) => Person.fullName(o.person)
            }));
            row.push(FormButtonComponent.makeLink('Phone', 'person.phone', '/people/${personId}'));
            row.push(FormButtonComponent.makeLink('Email', 'person.email', '/people/${personId}'));
        } */
        row.push(FormDateComponent.make('From', 'startDate', { cellOpts: { width: '2%' } }));
        row.push(FormDateComponent.make('Until', 'endDate', { cellOpts: { width: '2%' } }));
        row.push(FieldMaker.notes({ visible: Field.noShow }));
        row.push(FieldMaker.deleteGridRow());

        return row;
    }

    peopleGrid: GridField = new GridField(
        {
            field: { value: 'people', cellOpts: { heading: 'People' }, formColumn: 1, formRow: 2, visible: Field.formOnly },
            primaryId: 'ownerId', primaryCompareId: 'personId', onPrimarySet: (form, row) => {
                // const rowItemSelected = row.focus as PersonUnitRole;
                const fullName = row.get('person').value;
                //console.log('OnPrimarySet', { form, row, fullName, ctl: form.controls.ownerName });
                form.controls.ownerName.setValue(fullName);
                const role = (row.get('roleId') as AppPicklistControl).field.picklist.valueObject as Role;
                if (role) {
                    if (Role.ROLE.OWNER_OCCUPIER.id === role.globalRoleId) {
                        //console.log('Is owner occupied', role);
                        form.controls.ownerOccupied.setValue(true);
                    } else {
                        //console.log('Is NOT owner occupied', role);
                        form.controls.ownerOccupied.setValue(false);
                    }
                }
            },
            rowFactory: this.peopleRow.bind(this),
            objFactory: () => {
                const dialogRef = this.dialog.open(PickDialogComponent, { data: {
                    config: Person.pickDialogFormConfig(),
                    service: this.personSvc,
                    showSearch: true,
                    hideTabs: true,
                    forceTeamId: this.forceTeamId
                }});
                return dialogRef.afterClosed().pipe(first()).pipe<PersonUnitRole>(
                    map(this.plMapper)
                );
            }
        });

    portionGrid: GridField = new GridField(
        {
            field: { value: 'portions', cellOpts: { heading: 'Lease Portions' }, formRow: 2, sendServer: false, visible: Field.formOnly, },
            rowFactory: () => [
                FormButtonComponent.makeLink('Schedule', 'schedule.name', '/budgets/schedules/${scheduleId}'),
                FormNumberComponent.make('Portion', 'portion', {}, { cellOpts: { style: 'text-align: center' }, disable: true }),
                FormNumberComponent.make('Percent', 'percent', { format: 'percent', formatParms: '2.3-3' },
                    { cellOpts: { width: '80px', style: 'text-align: center' }, disable: true }
                ),
                FieldMaker.spacer(),
            ],
        });

    transactionGrid: GridField = new GridField(
        {
            field: {
                readonly: true, value: 'txnWithPayments', sendServer: false, visible: Field.formOnly,
                cellOpts: { heading: 'Transactions' }, tabIcon: 'euro_symbol'
            },
            rowFactory: (o: Txn) => [
                FormTextComponent.make('accordionTitle', 'title', {
                    visible: Field.noShow,
                    calculateValue: (o: Txn) => {
                        if (o.typeId === Txn.TYPE.BANK_IN_ALLOC.id || o.typeId === Txn.TYPE.BANK_IN_ON_ACCT.id) {
                            return `${o.type.name} ${o.parentRefNr} (${o.txnDate})`;
                        } else {
                            return `${o.type.name} ${o.refNr} (${o.txnDate})`;
                        }
                    }
                }),
                FormButtonComponent.makeLink('Ref#', 'refNr', Txn.getTxnLink(o), {
                    calculateValue: (o: Txn) => {
                        if (o.typeId === Txn.TYPE.BANK_IN_ALLOC.id || o.typeId === Txn.TYPE.BANK_IN_ON_ACCT.id) {
                            return o.parentRefNr;
                        } else {
                            return o.refNr;
                        }
                    }
                }),

                FormPicklistComponent.make('Type', 'typeId', 'type', { items: Txn.TYPES },
                    { readonly: true, cellOpts: { width: '10em' }, sendServer: false }
                ),

                FormDateComponent.make('Date', 'txnDate', { readonly: true, cellOpts: { width: '2%' }, footer: { text: 'Totals' } }),
                FormNumberComponent.make('Debit', 'debit',
                    { format: 'currency', width: 9, formatParms: '1.2-2' }, { readonly: true }
                ),
                FormNumberComponent.make('Credit', 'credit', { format: 'currency', width: 9, formatParms: '1.2-2' }, { readonly: true }),
                FormNumberComponent.make('Outstanding', 'outstanding', { format: 'currency', width: 9, formatParms: '1.2-2' }, { readonly: true }),
                FormNumberComponent.make('Running Balance', 'balance',
                    { format: 'currency', width: 9, formatParms: '1.2-2' }, { readonly: true, footer: { style: 'opacity: 0%' } }),
                FormTextAreaComponent.make('Notes', 'notes', { readonly: true }),

                //FieldMaker.notes().override({readonly: true, defaultReadonly: true}),
            ],
            hasFooter: true,

        });

    requestGrid = new GridField({
        field:
            { label: 'Requests', value: 'requests', sendServer: false, tabIcon: 'live_help' },
        rowFactory: (o: ServiceRequest) => [
            FormButtonComponent.makeLink('Ref', 'refNr', o ? ServiceRequestPageComponent.navRoute.getIdUrl(o.id) : '', {
                readonly: true, sendServer: false,
                cellOpts: { width: "5em", maxWidth: "5em" },
            }),
            FormTextComponent.make('Title', 'title', { readonly: true }),
            FormTextAreaComponent.make('Description', 'description', { readonly: true }),
            ServiceRequest.getStatusField(),
            ServiceRequest.getCreatedField(),
            ServiceRequest.getLastUpdatedField(),
        ]
    });

    yearStartField = FormNumberComponent.make('Total Charges (including arrears)', 'yearStart',
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { visible: Field.formOnly, readonly: true, formColumn: 4 }
    );

    currentPaysField = FormNumberComponent.make('Total Paid this year', 'currentPays',
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { visible: Field.formOnly, readonly: true, formColumn: 4, disable: true }
    );

    outstandingField = FormNumberComponent.make('Total Outstanding', 'outstanding',
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { visible: Field.formOnly, readonly: true, formColumn: 4, disable: true }
    );

    oldPayField = FormNumberComponent.make('', 'oldPay',
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { visible: Field.noShow, readonly: true, formColumn: 5, disable: true }
    );

    newPayField = FormNumberComponent.make('', 'newPay',
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { visible: Field.noShow, readonly: true, formColumn: 4, disable: true }
    );

    overdueField = FormNumberComponent.make('Overdue (or Credit if negative)', 'overdue',
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { visible: Field.formOnly, readonly: true, formColumn: 5, refresh: this.frequencyChanges.bind(this), disable: true }
    );

    payButton = FormButtonComponent.make('Pay Now', 'payNow', {
        type: 'icon',
        formColumn: 5,
        visible: Field.noShow, disable: true,
        calculateValue: () => 'euro',
        toolTip: $localize`Make payment now`,
        btnOpts: { clickMethod: this.payNow.bind(this), style: 'margin-top: 15px;' }
    });

    ownerField = FormTextComponent.make('ownerId', 'ownerId', { visible: Field.noShow });
    idField = FieldMaker.id();

    firesSafetyField = FormDateComponent.make('Fire Safety Checked', 'fireSafetyCheck', { formColumn: 5 });

    typeField = FormPicklistComponent.make('Type', 'typeId', 'type',
        { items: [], refreshes: ['categoryId'] }, { formColumn: 2 });

    categoryField = FormPicklistComponent.make('Category', 'categoryId', 'category', { items: [] }, {
        formColumn: 2,
        refresh: (o: UnitType, control: AppPicklistControl) => {
            if (o) {
                control.field.setPicklistItems(o.categories);
                if (control.value && !o.categories.find(c => c.id === control.value)) {
                    control.setValue(null);
                }
            }
        }
    });

    areaField = FormPicklistComponent.make('Area', 'areaId', 'area',
        { refreshes: ['coreId'], items: [] }, { formColumn: 1 });
    coreField = FormPicklistComponent.make('Core', 'coreId', 'core', { items: [] }, {
        formColumn: 1,
        refresh: (o: Area, control: AppPicklistControl) => {
            if (o) {
                control.field.setPicklistItems(o.cores);
                if (control.value && !o.cores.find(c => c.id === control.value)) {
                    control.setValue(null);
                }
            } }
    });

    ownerNavField = FormButtonComponent.makeNavDetailButton('Owner', 'ownerName', 'ownerId',
        PersonPageComponent.navRoute, true).override({
            calculateValue: (o: Unit) => Person.fullName(o?.owner),
            formColumn: 3
        });

    sizeField = FormNumberComponent.make('Size', 'size', { }, {
        formColumn: 2, validators: [numericRange(0, 99999)]
    });

    floorField = FormNumberComponent.make('Floor', 'floor', {}, {
        formColumn: 2, validators: [numericRange(0, 50)]
    });

    notesField = FieldMaker.notes({ formColumn: 3, visible: Field.formOnly });

    fields: Field[] = [
        FormTextComponent.make('Keyword Search', 'keyword', { visible: Field.noShow, sendServer: false }),
        this.idField,
        FieldMaker.rev(),
        FieldMaker.nameControl({ validators: [required] }),
        FormTextComponent.make('Address', 'address'),
        this.ownerField,
        this.ownerNavField,
        FormCheckboxComponent.make('Owner Occupied', 'ownerOccupied',
            {
                cellOpts: { width: '2%', style: 'text-align: center' }, hint: 'Edit on people tab', disable: true, visible: Field.formOnly
            }),
        FormTextAreaComponent.make('Owner Mailing Address', 'owner.address', {
            disable: true, sendServer: false, visible: Field.formOnly, formColumn: 3 }),


        this.typeField,
        this.categoryField,

        this.areaField,
        this.coreField,
        FormNumberComponent.make('Bedroom', 'bedrooms', {}, { formColumn: 2, validators: [numericRange(0, 10)] }),
        this.sizeField,
        this.floorField,
        this.notesField,

        FormNumberComponent.make('Number of car spaces', 'numberCarSpaces',
            { format: 'decimal', formatParms: '1.0-0' },
            { formColumn: 4 }
        ),

        this.yearStartField, this.currentPaysField, this.outstandingField, this.newPayField,

        FormTextComponent.make('Car Space(s)', 'carSpaces', { formColumn: 5 }),

        FormPicklistComponent.make('Payment Frequency', 'frequencyId', 'frequency',
            { items: Frequency.PAYMENT_OPTIONS, refreshes: ['overdue'] }, { formColumn: 5 }),
        this.overdueField, this.oldPayField,
        this.payButton,
        this.firesSafetyField,
        this.peopleGrid,
        this.portionGrid,
    ];

    config = new FormConfig({
        navRoute: UnitPageComponent.navRoute,
        title: $localize`Units`,
        help: $localize`The units that make up this multi unit development`,
        fieldSet: new FieldSet({
            fields: this.fields,
            formLayout: [
                { cells: [{ width: '25%' }, { width: '15%' }, { width: '30%' }, { width: '15%' }, { width: '15%' }] },
                { cells: [{ colspan: 5, pageTab: 'yes' }] }
            ]
        }),
        service: this.dataSvc,
        mode: 'list',
        actions: [
            new NewUnitRequestAction(), new AddActionFormAction(this.actionGrid),
            new AddMemoAction(this.memoField), new StatementPDFAction(),
        ],
        objectFactory: this.beforeNew.bind(this),
        beforeEdit: this.beforeEdit.bind(this),
        configReady: this.configReady,
        setTeam: this.setTeam.bind(this),
    });

    constructor(public dataSvc: UnitService, private router: Router, private currentUserSvc: CurrentUserService,
        public typeService: UnitTypeService, public areaService: AreaService, public categoriesService: UnitCategoryService,
        public roleService: RoleService, public personSvc: PersonService, protected payzService: PayzService,
        private activeRoute: ActivatedRoute, public dialog: MatDialog, cycleSvc: CycleService,
        private msgSvc: MessageService,
        protected http: HttpClient, protected cds: ConfirmDialogService, protected myUnitSvc: MyUnitService,
        @Optional() public dialogRef: MatDialogRef<UnitPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        super();

        if (activeRoute.snapshot.url[0]?.path === 'myunits') {
            this.mineOnly = true;
            this.dataSvc = this.myUnitSvc;
            this.config.navRoute = MyUnitPageComponent.navRoute;
            this.config.service = this.myUnitSvc;
            this.config.allowNew = false;
            this.config.allowEdit = false;
            this.config.tabFields = [this.transactionGrid];
            console.error('Should not be in here any more... there is a new myUnitPage');
        } else {
            this.mineOnly = false;
            this.config.tabFields = [this.actionGrid, this.memoField, this.requestGrid, this.transactionGrid, this.attachmentGrid];
            this.config.navRoute = UnitPageComponent.navRoute;
        }

        cycleSvc.get(true).pipe(first()).subscribe(() => {
            this.currentCycle = cycleSvc.getCurrentCycle();
            this.configReady.next(null);
        })
    }

    setTeam(teamId: uuid) {
        this.forceTeamId = teamId;
        this.ownerNavField.setNavTeam(teamId);
        this.typeService.get(false, null, teamId).subscribe(types => {
            this.typeField.setPicklistItems(types);
        });
        this.categoriesService.get(false, null, teamId).subscribe(cats => {
            this.categoryField.setPicklistItems(cats);
        });
        this.areaService.get<Area>(false, null, teamId).subscribe(areas => {
            this.areaField.setPicklistItems(areas);
            this.coreField.setPicklistItems(this.areaService.getCores(areas));
        });
        this.roleService.get(false, null, teamId).subscribe(roles => {
            this.roleList.length = 0;
            this.unitRoleList.length = 0;
            roles.forEach((r: Role) => {
                this.roleList.push(r);
                if (r.unitLinked) {
                    this.unitRoleList.push(r);
                }
            });
        });
        if (this.dialogRef && this.dialogOptions?.condensed) {
            this.currentPaysField.visible = Field.noShow;
            this.areaField.visible = Field.noShow;
            this.coreField.visible = Field.noShow;
            this.sizeField.visible = Field.noShow;
            this.floorField.visible = Field.noShow;
            this.notesField.visible = Field.noShow;
            this.firesSafetyField.visible = Field.noShow;
        }
    }

    ngOnInit(): void {
        this.currentUserSvc.getCurrentUser().subscribe(u => {

            this.currentUser = u;

            if (this.mineOnly) {
                if (!this.activeRoute.snapshot.params.itemId) {
                    if (this.currentUser.activePositions.length === 1) {
                        this.router.navigate(['/myunits/' + this.currentUser.activePositions[0].unitId]);
                    }
                }
            }
        })
    }

    frequencyChanges(newFrequency: Frequency) {
        //let unit = this.page.form.focusItem as Unit
        //unit.frequencyId =
        //unit.frequency = fld.picklist.valueObject as Frequency;

        const unit = JSON.parse(JSON.stringify(this.page.form.focusItem)) as Unit;
        unit.frequencyId = newFrequency.id;
        unit.frequency = newFrequency;

        this.calculatePaymentSchedule(unit);

        this.page.form.focusItem = unit;

        this.yearStartField.control.setValue(unit.yearStart, { emitEvent: false });
        this.currentPaysField.control.setValue(unit.currentPays, { emitEvent: false });
        this.outstandingField.control.setValue(unit.outstanding, { emitEvent: false });
        this.newPayField.control.setValue(unit.newPay, { emitEvent: false });
        this.overdueField.control.setValue(unit.overdue, { emitEvent: false });
        this.oldPayField.control.setValue(unit.oldPay, { emitEvent: false });

    }

    payNow() {
        const overDueAmt = this.overdueField.control.value;
        const overDueText = this.overdueField.formatValue(overDueAmt);

        const outstandingAmt = this.outstandingField.control.value;
        const outstandingText = this.outstandingField.formatValue(outstandingAmt);

        this.cds.openChoice({
            title: $localize`Make Payment`,
            msg: $localize`Make a Direct Debit Payment Now?`,
            options: [
                {
                    name: $localize`Pay Overdue ${overDueText}`, action: () => {
                        this.payzService.payNow(this.ownerField.control.value, this.idField.control.value, null, overDueAmt);
                    }
                },
                {
                    name: $localize`Pay All Outstanding ${outstandingText}`, action: () => {
                        this.payzService.payNow(this.ownerField.control.value, this.idField.control.value, null, outstandingAmt);
                    }
                }
            ]
        }, true);
    }

    beforeNew(): Observable<Unit> {
        this.yearStartField.visible = Field.noShow;
        this.currentPaysField.visible = Field.noShow;
        this.outstandingField.visible = Field.noShow;
        this.newPayField.visible = Field.noShow;
        this.overdueField.visible = Field.noShow;
        this.oldPayField.visible = Field.noShow;
        const unit = new Unit();

        return of(unit);
    }

    isCurrent(o: PersonUnitRole) {
        if ((o === null) || o === undefined) {
            return $localize`Unit Owner not listed in unit people, please select a main owner in the list`
        } else if (o.startDate && !DateHelper.beforeToday(o.startDate)) {
            return $localize`Unit Owner selected has not taken ownership of unit yet. If you need to billout before the new owner takes ownership, they will still receive the bill`
        } else if ((o.endDate && DateHelper.beforeToday(o.endDate))) {
            return $localize`Unit Owner selected is no longer listed as owner, you should update the main owner`
        } else {
            return null;
        }
    }
    checkOwner(o: Unit) {

        const oPerson = o.people.find(p => p.personId === o.ownerId);
        const status = this.isCurrent(oPerson);

        if (status !== null) {
            this.msgSvc.show(new MsgDef(status, 'warn'));
        } else {
            if ( oPerson.role.globalRoleId === Role.ROLE.OWNER.id) {
                if (!oPerson.person?.address || (oPerson.person?.address && oPerson.person.address.indexOf("\n") < 0)) {
                    const msg = `Owners are non resident - You need to either mark ${oPerson.person?.fullName} as an Owner Occupier,
                    or click on them and add a mailing address`
                    this.msgSvc.show(new MsgDef(msg, 'warn'));
                }
            }
        }
    }

    beforeEdit(o: Unit): Unit {
        if (this.dialogRef) {
            this.peopleGrid.control.objFactory = null;
        }
        if (o === null) {
            return new Unit();
        }
        this.checkOwner(o);
        this.yearStartField.visible = Field.formOnly;

        if (!this.dialogOptions?.condensed) {
            this.currentPaysField.visible = Field.formOnly;
        }

        this.outstandingField.visible = Field.formOnly;
        this.newPayField.visible = Field.formOnly;
        this.overdueField.visible = Field.formOnly;

        o.people = o.people.sort(new UnitRoleSorter().sort);

        const payMap: Map<uuid, Txn> = new Map();
        o.txnWithPayments = [];
        let balance = 0;
        for (const txn of o.transactions) {
            balance -= txn.credit;
            balance += txn.debit;
            if (txn.typeId === Txn.TYPE.BANK_IN_ALLOC.id || txn.typeId === Txn.TYPE.BANK_IN_ON_ACCT.id) {
                if (!payMap.has(txn.parentId)) {
                    const p = new Txn();
                    p.id = txn.parentId;
                    p.typeId = Txn.TYPE.BANK_IN.id;
                    p.type = Txn.TYPE.BANK_IN;
                    p.txnDate = txn.txnDate;
                    p.credit = txn.credit;
                    p.outstanding = txn.outstanding;
                    p.refNr = txn.parentRefNr;
                    payMap.set(txn.parentId, p);
                    o.txnWithPayments.push(p);
                } else {
                    payMap.get(txn.parentId).credit += txn.credit;
                    payMap.get(txn.parentId).outstanding += txn.outstanding;
                }
            } else {
                o.txnWithPayments.push(txn);
            }
        }
        o.txnWithPayments = o.txnWithPayments.sort((a, b) => {
            const keyA = this.getKey(a);
            const keyB = this.getKey(b);
            if (keyA > keyB) {
                return -1;
            } else if (keyA < keyB) {
                return 1;
            } else {
                return 0;
            }
        });
        o.txnWithPayments.forEach(txn => {
            txn.balance = balance;
            balance += txn.credit;
            balance -= txn.debit;
        })

        return this.calculatePaymentSchedule(o);

    }

    private getKey(t: Txn) {
        return t.txnDate + t.id;
    }

    calculatePaymentSchedule(o: Unit) {
        let outstanding = 0;
        let currentPays = 0;

        for (const t of o.transactions) {
            outstanding += t.outstanding;
            if (t.typeId === Txn.TYPE.BANK_IN_ALLOC.id || t.typeId === Txn.TYPE.BANK_IN_ON_ACCT.id) {
                if (t.txnDate >= this.currentCycle.from) {
                    currentPays += t.credit;
                }
            }
        }

        const daysIn = DateHelper.isoDaysSince(this.currentCycle.from);
        const daysTo = DateHelper.isoDaysTo(this.currentCycle.to);

        let paysExpected = o.frequency.perAnnum;
        if (daysTo > 0) {
            paysExpected = 1 + Math.trunc(daysIn / (daysIn + daysTo) * o.frequency.perAnnum);
        }

        const paysLeft = o.frequency.perAnnum - paysExpected;

        o.yearStart = outstanding + currentPays;
        o.oldPay = o.yearStart / o.frequency.perAnnum;
        o.currentPays = currentPays;
        o.outstanding = outstanding;
        o.overdue = (paysExpected * o.oldPay) - currentPays;
        o.newPay = paysLeft ? o.outstanding / paysLeft : 0;

        if (paysLeft > 1 && outstanding > 0.01) {
            this.oldPayField.visible = Field.formOnly;
            this.oldPayField.label = $localize` ${paysLeft} Payments if overdue cleared first`
            this.newPayField.visible = Field.formOnly;
            this.newPayField.label = $localize` ${paysLeft} Payments to clear account`
        } else {
            this.oldPayField.visible = Field.noShow;
            this.newPayField.visible = Field.noShow;
        }
        return o;
    }

    roleUpdate(newValue: Role, ctl: AppFormControl) {
        if (ctl.getRow().isPrimary()) {
            const ownerOccupied = newValue.globalRoleId === Role.ROLE.OWNER_OCCUPIER.id;
            ctl.setParentFormField('ownerOccupied', ownerOccupied);
        }
    }

    plMapper(itemPicked): PersonUnitRole {
        if (itemPicked) {
            const newItem = new PersonUnitRole();
            newItem.personId = itemPicked.id;
            newItem.person = itemPicked;
            return newItem;
        } else {
            return null;
        }
    }
}

export class NewUnitRequestAction implements IFormAction {
    name = $localize`Create Request`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'add_circle';
    approvalNeeded = false;
    disabled = false;
    approvalText = "";
    tipText: string;
    teamId: uuid;

    unit: Unit;


    action(unit: Unit) {
        const router = MyInjector.instance.get(Router);
        const cu = MyInjector.instance.get(CurrentUserService);
        if (cu.currentUser?.id && this.teamId) {
            const omcId = cu.getCurrentOmc(this.teamId).id;
            const parms = { unitId: unit.id, omcId } as Params;
            if (unit.ownerId) {
                parms.personId = unit.ownerId;
            }
            const route = ['/' + ServiceRequestPageComponent.navRoute.newRoute.path, parms];
            router.navigate(route);
        }
        return of(this.unit);
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(unit: Unit) {
        if (unit?.teamId) {
            this.teamId = unit.teamId;
            this.show = true;
        } else {
            this.show = false;
        }
        this.unit = unit;
    }
}
