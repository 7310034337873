
/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Injectable } from "@angular/core";
import { Router, Routes } from "@angular/router";

import { User } from "../model/user";
import { AgentDashboardPageComponent } from "../modules/agent/agent-dashboard-page/agent-dashboard-page.component";
import { OmcPageComponent } from "../modules/agent/omc-page/omc-page.component";
import { BCodePageComponent } from "../modules/budget/b-code-page/b-code-page.component";
import { BillingPageComponent } from "../modules/budget/billing-page/billing-page.component";
import { BudgetingPageComponent } from "../modules/budget/budgeting-page/budgeting-page.component";
import { CyclePageComponent } from "../modules/budget/cycle-page/cycle-page.component";
import { SchedulePageComponent } from "../modules/budget/schedule-page/schedule-page.component";
import { ActionPageComponent } from "../modules/crm/action-page/action-page.component";
import { DocumentPageComponent } from "../modules/crm/document-page/document-page.component";
import { NewUserRequestComponent } from "../modules/crm/new-user-request/new-user-request.component";
import { PersonalRequestsComponent } from "../modules/crm/personal-requests/personal-requests.component";
import { RecurringJobComponent } from "../modules/crm/recurring-job/recurring-job.component";
import { RequestCategoriesPageComponent } from "../modules/crm/request-categories-page/request-categories-page.component";
import { RequestPrioritiesPageComponent } from "../modules/crm/request-priorities-page/request-priorities-page.component";
import { RequestTemplateComponent } from "../modules/crm/request-template/request-template.component";
import { ServiceRequestPageComponent } from "../modules/crm/service-request-page/service-request-page.component";

import { ImportPageComponent } from "../modules/importer/import-page/import-page.component";
import { BallotPageComponent } from "../modules/social/ballot-page/ballot-page.component";
import { ForumComponent } from "../modules/social/forum/forum.component";
import { PostListComponent } from "../modules/social/post/post-list/post-list.component";
import { QuestionPageComponent } from "../modules/social/question-page/question-page.component";
import { PreferredSupplierPageComponent } from "../modules/supply/preferred-supplier-page/preferred-supplier-page.component";
import { SupplierPageComponent } from "../modules/supply/supplier-page/supplier-page.component";
import { ApPageComponent } from "../modules/txn/ap-page/ap-page.component";
import { ArPageComponent } from "../modules/txn/ar-page/ar-page.component";
import { BankAccountPageComponent } from "../modules/txn/bank-account-page/bank-account-page.component";
import { BankInPageComponent } from "../modules/txn/bank-in-page/bank-in-page.component";
import { BankOutPageComponent } from "../modules/txn/bank-out-page/bank-out-page.component";
import { BankPageComponent } from "../modules/txn/bank-page/bank-page.component";
import { CreditNotePageComponent } from "../modules/txn/credit-note-page/credit-note-page.component";
import { InvoicePageComponent } from "../modules/txn/invoice-page/invoice-page.component";
import { ConfirmPageComponent } from "../modules/txn/payments/confirm-page/confirm-page.component";
import { PaymentOptionsComponent } from "../modules/txn/payments/payment-options/payment-options.component";
import { PurchasePageComponent } from "../modules/txn/purchase-page/purchase-page.component";
import { PurchaseUploadsPageComponent } from "../modules/txn/purchase-uploads-page/purchase-uploads-page.component";
import { TxnPageComponent } from "../modules/txn/txn-page/txn-page.component";
import { AreaPageComponent } from "../modules/unit/area/area-page.component";
import { UnitPageComponent } from "../modules/unit/unit-page/unit-page.component";
import { UnitTypePageComponent } from "../modules/unit/unit-type-page/unit-type-page.component";
import { CurrentUserService } from "../modules/user/current-user.service";
import { LoginComponent } from "../modules/user/login/login.component";
import { RegisterUserComponent } from "../modules/user/register/register.component";
import { TeamUserPageComponent } from "../modules/user/team-user-page/team-user-page.component";
import { UserProfileComponent } from "../modules/user/user-profile/user-profile.component";
import { HomePageComponentComponent } from "../pages/home-page-component/home-page-component.component";
import { OAuthCallbackComponent } from "../pages/o-auth-callback/o-auth-callback.component";
import { PersonPageComponent } from "../pages/person-page/person-page.component";
import { RolePageComponent } from "../pages/role-page/role-page.component";
import { NavRoute } from "./NavRoute";
import { SearchPageComponent } from "../pages/search-page/search-page.component";
import { RequestAnalysisComponent } from "../modules/crm/request-analysis/request-analysis.component";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { JournalPageComponent } from "../modules/txn/journal-page/journal-page.component";
import { OmcSignUpComponent } from "../modules/user/omc-sign-up/omc-sign-up.component";
import { TrialBalanceComponent } from "../modules/txn/balance/trial-balance/trial-balance.component";
import { IncomeExpenditureComponent } from "../modules/txn/balance/income-expenditure/income-expenditure.component";
import { BalanceSheetComponent } from "../modules/txn/balance/balance-sheet/balance-sheet.component";
import { DebtorsListComponent } from "../modules/txn/debtors-list/debtors-list.component";
import { BankReconciliationComponent } from "../modules/txn/bank-reconciliation/bank-reconciliation.component";
import { DebtorsAnalysisComponent } from "../modules/txn/debtors-analysis/debtors-analysis.component";
import { FixedAssetsPageComponent } from "../modules/preventative/fixed-assets-page/fixed-assets-page.component";
import { FixedAssetTypesPageComponent } from "../modules/preventative/fixed-asset-types-page/fixed-asset-types-page.component";
import { ImageUploadRequestComponent } from "../modules/crm/service-request-page/image-upload-request/image-upload-request.component";
import { OmcSettingsComponent } from "../pages/omc-settings/omc-settings.component";
import { TrialBalanceImportComponent } from "../modules/txn/balance/trial-balance-import/trial-balance-import.component";
import { ResidentsHomePageComponent } from "../modules/public/residents/residents.component";
import { OwnersHomePageComponent } from "../modules/public/owners/owners.component";
import { DirectorsHomePageComponent } from "../modules/public/directors/directors.component";
import { AgentsHomePageComponent } from "../modules/public/agents/agents.component";
import { AccountantsHomePageComponent } from "../modules/public/accountants/accountants.component";
import { ContractorsHomePageComponent } from "../modules/public/contractors/contractors.component";
import { PricingComponent } from "../modules/public/pricing/pricing.component";
import { JoinAnOmcComponent } from "../modules/user/join-an-omc/join-an-omc.component";
import { DemosComponent } from "../modules/public/demos/demos.component";
import { AboutUsComponent } from "../modules/public/about-us/about-us.component";
import { ContactUsComponent } from "../modules/public/contact-us/contact-us.component";
import { MyUnitPageComponent } from "../modules/unit/my-unit-page/my-unit-page.component";
import { CommsTemplatePageComponent } from "../modules/social/comms-template-page/comms-template-page.component";
import { MailMergePageComponent } from "../modules/agent/mail-merge-page/mail-merge-page.component";
import { AgentSettingsComponent } from "../pages/agent-settings/agent-settings.component";

export interface AppRouteAble {
    readonly navRoute: NavRoute;
}

export const appRoutes: Routes = [
    /*
    {
        path: 'potter/:itemId',
        component: PotterComponent,
    },
    {
        path: 'potter',
        component: PotterComponent,
    },
    */
    {
        path: 'termsOfService',
        component: TermsOfServiceComponent,
    },

    AgentDashboardPageComponent.navRoute.listRoute,

    OmcPageComponent.navRoute.listRoute,
    OmcPageComponent.navRoute.itemRoute,

    MailMergePageComponent.navRoute.listRoute,
    MailMergePageComponent.navRoute.itemRoute,

    SearchPageComponent.navRoute.listRoute,

    UserProfileComponent.navRoute.openRoute, // not really open, will divert if currentUser not retrieved.

    BCodePageComponent.navRoute.listRoute,
    BCodePageComponent.navRoute.itemRoute,

    LoginComponent.navRoute.openRoute,
    NewUserRequestComponent.navRoute.openRoute,
    PersonalRequestsComponent.navRoute.listRoute, // In case not logged in... openRoute,
    PersonalRequestsComponent.navRoute.itemRoute, // so not open

    RegisterUserComponent.navRoute.openRoute,
    OmcSignUpComponent.navRoute.openRoute,


    PersonPageComponent.navRoute.listRoute,
    PersonPageComponent.navRoute.itemRoute,

    TeamUserPageComponent.navRoute.listRoute,
    TeamUserPageComponent.navRoute.newRoute,
    TeamUserPageComponent.navRoute.itemRoute,

    TxnPageComponent.navRoute.listRoute,
    TrialBalanceComponent.navRoute.listRoute,
    TrialBalanceImportComponent.navRoute.openItemRoute,
    IncomeExpenditureComponent.navRoute.listRoute,
    BalanceSheetComponent.navRoute.listRoute,

    ArPageComponent.navRoute.listRoute,
    //ArPageComponent.navRoute.itemRoute,

    FixedAssetsPageComponent.navRoute.listRoute,
    FixedAssetsPageComponent.navRoute.itemRoute,

    FixedAssetTypesPageComponent.navRoute.listRoute,
    FixedAssetTypesPageComponent.navRoute.itemRoute,

    InvoicePageComponent.navRoute.listRoute,
    InvoicePageComponent.navRoute.itemRoute,

    DebtorsListComponent.navRoute.listRoute,
    DebtorsAnalysisComponent.navRoute.listRoute,

    BankReconciliationComponent.navRoute.listRoute,
    BankReconciliationComponent.navRoute.itemRoute,

    CreditNotePageComponent.navRoute.listRoute,
    CreditNotePageComponent.navRoute.itemRoute,

    PurchasePageComponent.navRoute.listRoute,
    PurchaseUploadsPageComponent.navRoute.listRoute,
    PurchasePageComponent.navRoute.itemRoute,

    ConfirmPageComponent.navRoute.openRoute,

    PaymentOptionsComponent.navRoute.openRoute,

    BankPageComponent.navRoute.listRoute,
    BankPageComponent.navRoute.itemRoute,

    BankAccountPageComponent.navRoute.listRoute,
    BankAccountPageComponent.navRoute.itemRoute,

    PreferredSupplierPageComponent.navRoute.listRoute,
    PreferredSupplierPageComponent.navRoute.itemRoute,

    SupplierPageComponent.navRoute.listRoute,
    SupplierPageComponent.navRoute.itemRoute,

    BankInPageComponent.navRoute.listRoute,
    BankInPageComponent.navRoute.itemRoute,

    BankOutPageComponent.navRoute.listRoute,
    BankOutPageComponent.navRoute.itemRoute,

    ApPageComponent.navRoute.listRoute,
    ApPageComponent.navRoute.itemRoute,

    TxnPageComponent.navRoute.itemRoute,

    JournalPageComponent.navRoute.listRoute,
    JournalPageComponent.navRoute.itemRoute,

    { path: 'oAuthCallback', component: OAuthCallbackComponent },

    //PostListComponent.navRoute.openRoute,
    PostListComponent.navRoute.listRoute, // is an item view, but avoiding duplicate path...
    PostListComponent.navRoute.itemRoute,

    ForumComponent.navRoute.listRoute,
    ForumComponent.navRoute.itemRoute,

    BallotPageComponent.navRoute.listRoute,
    BallotPageComponent.navRoute.itemRoute,

    QuestionPageComponent.navRoute.listRoute,
    QuestionPageComponent.navRoute.itemRoute,


    RecurringJobComponent.navRoute.itemRoute,
    RecurringJobComponent.navRoute.listRoute,

    ServiceRequestPageComponent.navRoute.listRoute,
    ServiceRequestPageComponent.navRoute.newRoute,
    ServiceRequestPageComponent.navRoute.itemRoute,
    ImageUploadRequestComponent.navRoute.listRoute,

    RequestAnalysisComponent.navRoute.listRoute,

    ActionPageComponent.navRoute.listRoute,
    ActionPageComponent.navRoute.itemRoute,

    UnitTypePageComponent.navRoute.listRoute,
    UnitTypePageComponent.navRoute.itemRoute,

    RolePageComponent.navRoute.listRoute,
    RolePageComponent.navRoute.itemRoute,

    AreaPageComponent.navRoute.listRoute,
    AreaPageComponent.navRoute.itemRoute,

    RequestTemplateComponent.navRoute.itemRoute,
    RequestTemplateComponent.navRoute.listRoute,

    CommsTemplatePageComponent.navRoute.itemRoute,
    CommsTemplatePageComponent.navRoute.listRoute,

    RequestCategoriesPageComponent.navRoute.itemRoute,
    RequestCategoriesPageComponent.navRoute.listRoute,

    RequestPrioritiesPageComponent.navRoute.itemRoute,
    RequestPrioritiesPageComponent.navRoute.listRoute,

    SchedulePageComponent.navRoute.itemRoute,
    SchedulePageComponent.navRoute.listRoute,

    CyclePageComponent.navRoute.itemRoute,
    CyclePageComponent.navRoute.listRoute,

    BillingPageComponent.navRoute.itemRoute,
    BillingPageComponent.navRoute.listRoute,

    BudgetingPageComponent.navRoute.itemRoute,
    BudgetingPageComponent.navRoute.listRoute,


    DocumentPageComponent.navRoute.listRoute,
    DocumentPageComponent.navRoute.itemRoute,

    UnitPageComponent.navRoute.itemRoute,
    UnitPageComponent.navRoute.listRoute,

    MyUnitPageComponent.navRoute.listRoute,

    ImportPageComponent.navRoute.listRoute,

    //HomePageComponentComponent.navRoute.openRoute,
    { path: '', component: HomePageComponentComponent, data: {animation: 'HomePage'}},
    { path: 'pricing', component: PricingComponent, data: {animation: 'PricingPage'}},
    { path: 'residents', component: ResidentsHomePageComponent, data: {animation: 1} },
    { path: 'owners', component: OwnersHomePageComponent, data: {animation: 2 }},
    { path: 'directors', component: DirectorsHomePageComponent, data: {animation: 3 }},
    { path: 'agents', component: AgentsHomePageComponent, data: {animation: 4 }},
    { path: 'accountants', component: AccountantsHomePageComponent, data: {animation: 5 }},
    { path: 'contractors', component: ContractorsHomePageComponent, data: {animation: 6 }},
    { path: 'demos', component: DemosComponent, data: {animation: 'demos'}},
    { path: 'about-us', component: AboutUsComponent, data: {animation: 'about-us'}},
    { path: 'contact-us', component: ContactUsComponent, data: {animation: 'contact-us'}},

    {path: 'unknownUser', component: JoinAnOmcComponent},

    OmcSettingsComponent.navRoute.listRoute,
    AgentSettingsComponent.navRoute.listRoute,
];


@Injectable({
    providedIn: 'root'
})
export class AppRouter {

    currentUser: User;

    constructor(private currentUserSvc: CurrentUserService, private router: Router) {
        currentUserSvc.getCurrentUser().subscribe(u => {
            this.currentUser = u;
        });
    }

}
