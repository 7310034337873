/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { MessageService } from 'src/app/shared/message.service';
import { maxValue, minValue, required } from 'src/app/shared/validators';

@Component({
  selector: 'app-pricing-component',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule, MatTooltipModule,
    FormsModule, RouterLink, ReactiveFormsModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})
export class PricingComponent {
    cds = inject(ConfirmDialogService);
    msgSvc = inject(MessageService);

    unitCount = 0;
    tooManyUnits = false;
    /** , */
    unitCountCtl = new FormControl(0, [required, minValue(0), maxValue(2000)]);
    agentLinked = true;

    // Prices
    omcUnitPrice = 1;
    omcAgentLicensePrice = 50;
    agentLicensePrice = 50;
    agentMicrosoft = 65;
    vatRate = .23;
    agentCommission = .50;

    agentUnitCount = 0;
    agentUnitsOut = 0;
    agentUsers = 0;
    tooManyAgentUnits = false;

    agentUnitTipText = `Enter the total number of units you wish to manage`

    agentUnitsOutTip = `Enter the number units you expect to pay for their own license.
                        The agent price is reduced when OMCs bring their own license`

    agentUserTipText = `Enter the total number of staff in your office who would need to access the system`

    checkUnits($event, min = 0, max = 2000) {
        const val = $event.target.value;
        if (+val > max) {
            this.unitCount = max;
            this.tooManyUnits = true
            $event.target.value = max;
        } else if (+val < min){
            this.unitCount = min;
            console.warn({$event, min, max, value: $event.target.value});
            $event.target.value = min;
        } else {
            this.tooManyUnits = false;
        }
    }

    checkAgentUnits($event, min = 0, max = 9999) {
        const val = $event.target.value;
        if (+val > max) {
            this.agentUnitCount = max;
            this.tooManyAgentUnits = true
            $event.target.value = max;
        } else if (+val < min){
            this.agentUnitCount = min;
            $event.target.value = min;
        } else {
            this.tooManyAgentUnits = false;
        }
    }

    checkAgentUsers($event, min = 0, max = 20) {
        const val = $event.target.value;
        if (+val > max) {
            this.agentUsers = max;
            this.tooManyAgentUnits = true
            $event.target.value = max;
        } else if (+val < min){
            this.agentUsers = min;
            $event.target.value = min;
        } else {
            this.tooManyAgentUnits = false;
        }
    }

    checkAgentOwnLicense($event) {
        const max = this.agentUnitCount
        const min = 0;
        const val = $event.target.value;
        if (+val > max) {
            this.agentUnitsOut = max;
            $event.target.value = max;
        } else if (+val < min){
            this.agentUnitsOut = min;
            $event.target.value = min;
        }
    }

    calcOmcPrice() {
        if (this.unitCount === 0) {
            return 1;
        }
        if (this.agentLinked) {
            return Math.max(20, (this.unitCount * this.omcUnitPrice));
        } else {
            return Math.max(40, (this.unitCount * this.omcUnitPrice) + this.omcAgentLicensePrice);
        }
    }

    getAgentPriceExact() {
        let agentPrice = this.agentUsers * this.agentLicensePrice + ((this.agentUnitCount) * .25);
        agentPrice -= (this.agentUnitsOut * this.omcUnitPrice) / (1 + this.vatRate) * this.agentCommission;
        return agentPrice;
    }

    calcAgentPrice() {
        if (this.agentUsers) {
            return Math.round(Math.max(0, this.getAgentPriceExact()));
        }
        return this.agentLicensePrice;

    }

    changeAgentUnits() {
        if (this.agentUnitsOut > 0) {
            return;
        }
        const pay = Math.round(this.agentUnitCount * .9);

        const title = $localize `Bill units directly`;
        const msg = $localize `We expect agents to provide us contact details so that we can bill the OMC directly
            for their units. For this price we will assume that 90% OF omc units will pay for their own license.
            You can adjust the number of units with their own license to see how this will effect your total cost.
            Once you have introduced sufficient units who pay their own license,
            the cost to you the agent for our services will go to zero
            `;
        this.cds.open(title, msg, () => this.agentUnitsOut = pay);

    }

    calcFullAgentPrice() {
        if (this.agentUsers) {
            let agentPrice = this.getAgentPriceExact();
            agentPrice += (this.agentMicrosoft * this.agentUsers)
            agentPrice = Math.round(agentPrice);
            return Math.max(0, agentPrice);
        } else {
            return this.agentLicensePrice + this.agentMicrosoft;
        }
    }

}
