@if (config.mode !== 'list') {
  <div [ngClass]="{'basecard': !dialogRef}"
    [style.width.px]="dialogWidth" [style.height.px]="dialogHeight" [style.overflow-y]="dialogRef ? 'auto' : null">
    @if (!isPhone && !isPrint) {
      <div style="right: 0; position: absolute; z-index: 100; margin-right:30px">
        <ng-container *ngTemplateOutlet="customActions"></ng-container>
      </div>
    }
    @if (isPhone && config.actions && config.actions.length > 0) {
      <div
        style="margin-right:15px; right: 0">
        <ng-container *ngTemplateOutlet="customActions"></ng-container>
      </div>
    }
    @if (dialogRef?.componentInstance?.dialogOptions?.hideTabs || isPrint) {
      <ng-container *ngTemplateOutlet="formContent"></ng-container>
    }
    @if (!dialogRef?.componentInstance?.dialogOptions?.hideTabs && !isPrint) {
      <mat-tab-group
        [(selectedIndex)]="selectedTopTab" (selectedTabChange)="topTabChanges($event)"
        mat-align-tabs="start" mat-stretch-tabs="false">
        <!-- [style.max-height.px]="formHeight" style="overflow:auto" -->
        <mat-tab>
          <ng-template mat-tab-label>
            <label style="pointer-events: all; text-align: center; align-items: center;"
              matTooltip="Item Details">
              <mat-icon style="height: 28px; width: 28px; font-size: 28px" color="primary">home</mat-icon>
            </label>
          </ng-template>
          <ng-template matTabContent>
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
          </ng-template>
        </mat-tab>
        @for (field of config.tabFields; track field) {
          <mat-tab [disabled]="field.disable">
            <ng-template mat-tab-label>
              <label style="pointer-events: all;" [matTooltip]="field.getTabLabel()">
                <!-- div style="display: flex" -->
                <!--div style="flex:10">{{field.getTabLabel(field.control?.value)}}</div field.getTabLabel(field.control?.value)-->
                @if (field.tabIcon) {
                  <mat-icon style="height: 24px; width: 24px; font-size: 24px" color="primary">{{field.tabIcon}}</mat-icon>
                }
                @if (!field.tabIcon) {
                  <div style="flex:3">{{field.getTabLabel()}}</div>
                }
                <!-- div style="margin-top: 1px; margin-bottom: 1px" class="our-tab-chip" *ngIf="!isPhone && field.getTabChip(field.control?.value)" -->
                @if (field.getTabChip(field.control?.value)) {
                  <button mat-mini-fab style="width: 18px; height: 18px; margin-bottom: 3px">{{field.getTabChip(field.control?.value)}}</button>
                }
                <!-- /div -->
                <!-- /div -->
              </label>
            </ng-template>
            <ng-template matTabContent>
              <div formHolder [style.max-height.px]="(isPhone || isPrint) ? null : formHeight"
              style="padding: 1em 10px 10px; padding-bottom:1em; overflow:auto">
                <app-ctl-holder [control]="field.control"></app-ctl-holder>
              </div>
            </ng-template>
          </mat-tab>
        }
      </mat-tab-group>
    }
    <mat-card-content>
      <!-- appFormFieldHolder -->
      <!-- mat-error *ngIf="!initComplete">You have no controls on form, make sure you have declared page.pageFormArray and
      called initFormObject on page</mat-error -->
      <!-- mat-divider style="margin-top: 10px; margin-bottom:10px"></mat-divider -->
    </mat-card-content>
  </div>
}
<ng-template #formContent>
  <div class="formHolder" formHolder [style.max-height.px]="(isPhone || isPrint) ? null : formHeight"
    style="padding: 1em 10px 0px; overflow:hidden" [attr.data-is-print]="isPrint">
    @if (!initComplete || loadingData) {
        <div style="position: fixed; z-index: 99; margin-left: 30vw; margin-top: 10vh">
            <mat-spinner [diameter]="100"></mat-spinner>
        </div>
    }
    @if (initComplete) {
        <ng-content></ng-content>
    }
    <app-field-set [fieldSet]="config.fieldSet" [isPhone]="isPhone" [isPrint]="isPrint"
      [initComplete]="initComplete">
    </app-field-set>
    @if (isPrint && initComplete) {
      @for (tabField of config.tabFields; track tabField) {
        <div>
          <h2 class="mat-h2" style="margin-top: 1em">{{tabField.label}}</h2>
          <!-- div formHolder [style.max-height.px]="(isPhone || isPrint) ? null : formHeight"
          style="padding-top: 1em; padding-bottom:1em; overflow:auto" -->
          <app-ctl-holder [control]="tabField.control"></app-ctl-holder>
          <!-- /div -->
        </div>
      }
    }
    @if (!isPhone && formGroup.pristine) {
      <app-field-set [fieldSet]="config.fieldSet"
        [isPhone]="isPhone" [initComplete]="initComplete" [showAfter]="true">
      </app-field-set>
    }
  </div>
  <ng-container *ngTemplateOutlet="stdActions"></ng-container>
</ng-template>
<ng-template #stdActions>
  @if (!isPrint) {
    <div style="margin-bottom:5px; margin-top: 5px">
      @if (!dialogRef) {
        <app-form-actions [(pageForm)]="formGroup" (cancel)="cancelChanges()" (save)="saveChanges()"
          (saveAndNew)="saveChangesAndNew()" (delete)="deleteObject()" [hideSaveAndNew]="config.mode === 'edit'"
          [(myMode)]="config.mode" [readonly]="config.readonly" [allowDelete]="config.allowDelete">
        </app-form-actions>
      }
      @if (dialogRef) {
        <app-form-actions [(pageForm)]="formGroup" (save)="saveChanges()" (cancel)="cancelChanges()"
          [(myMode)]="config.mode" [readonly]="config.readonly">
        </app-form-actions>
      }
    </div>
  }
</ng-template>
<ng-template #customActions>
  @if (getActionCount() <= 7) {
    @for (action of config.actions; track action) {
      @if (action.show) {
        @if (action.icon) {
            <div [matTooltip]="getActionTip(action)" style="display: inline">
                <button (click)="takeAction(action)"
                [disabled]="action.disabled || !formGroup.pristine"
                style="margin-bottom: 10px; margin-right: 10px"
                mat-mini-fab [color]="action.color">
                    <mat-icon>{{action.icon}}</mat-icon>
                </button>
            </div>
        } @else {
            <div [matTooltip]="getActionTip(action)" style="display: inline">
                <button (click)="takeAction(action)" [disabled]="action.disabled || !formGroup.pristine"
                    style="margin-bottom: 19px; margin-right: 10px" mat-raised-button [color]="action.color">
                    <span>{{action.name}}</span>
                </button>
            </div>
        }
      }
    }
  }
  @if (getActionCount() > 7) {
    <ng-container [ngTemplateOutlet]="customActionMenu"></ng-container>
  }
</ng-template>
<ng-template #customActionMenu>
  <!-- ng-container *ngFor="let action of config.actions">
  <button (click)="takeAction(action)" *ngIf="action.show"
    [disabled]="action.disabled || !formGroup.pristine" [matTooltip]="getActionTip(action)"
    style="margin-bottom: 10px; padding-left: 0; padding-right: 0px;" mat-icon-button [color]="action.color">
    <span *ngIf="!action.icon">{{action.name}}</span>
    <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
  </button>
  </ng-container -->
  <button mat-raised-button #menuTrigger="matMenuTrigger" color="primary" [matMenuTriggerFor]="customOptions"
    style="z-index: 50" matTooltip="Click to see available actions">
    {{getActionCount()}} Actions
  </button>
  <mat-menu #customOptions="matMenu">
    @for (action of config.actions; track action) {
      @if (action.show) {
        <button (click)="takeAction(action)" [disabled]="action.disabled || !formGroup.pristine"
          [matTooltip]="getActionTip(action)" mat-menu-item>
          @if (action.icon) {
            <mat-icon [color]="action.color">{{action.icon}}</mat-icon>
          }
          <span>{{action.name}}</span>
        </button>
      }
    }
  </mat-menu>
</ng-template>
