/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BCode } from 'src/app/model/bcode';
import { BCodeSchedule } from 'src/app/model/bcode-schedule';
import { Field } from 'src/app/shared/field/Field';
import { Schedule } from 'src/app/model/schedule';
import { DialogOptions, PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';

import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { required, maxChars, numericRange, percentZeroOrOne } from 'src/app/shared/validators';
import { BCodeService } from '../bcode.service';
import { ScheduleService } from '../schedule.service';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { AbstractObject } from 'src/app/model/abstract-object';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { Cycle } from 'src/app/model/cycle';
import { ButtonField } from 'src/app/shared/field/ButtonField';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';

@Component({
    selector: 'app-b-code-page',
    templateUrl: './b-code-page.component.html',
    styleUrls: ['./b-code-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class BCodePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('budgets/bcodes', BCodePageComponent, 'code').setViews(() => {
        return [
            {
                id: 'allBCode',
                name: 'All Budget Codes',
                filterFields: {
                    typeId: 'All',
                    name: null,
                    sort: null,
                }
            }
        ];
    });

    allSchedules: Schedule[];
    scheduleGrid: GridField = new GridField({
        field: { label: 'Schedule Allocation', value: 'schedule', formColumn: 3, visible: Field.formOnly },
        rowFactory: () => [
            FieldMaker.id(),
            FieldMaker.id({ value: 'scheduleId', name: 'scheduleId' }),
            FormButtonComponent.makeLink('Schedule', 'schedule.name', '/budgets/schedules/${scheduleId}'),
            FormNumberComponent.make('Percentage', 'percent',
                { format: 'percent', formatParms: '2.3-3', step: .01 }, { validators: [numericRange(0, 100)] }
            ),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => {
            const dialogRef = this.dialog.open(PickDialogComponent, {
                data: {
                    config: Schedule.pickDialogFormConfig(),
                    service: this.scheduleSvc,
                    showSearch: false
                }
            });
            return dialogRef.afterClosed().pipe(first()).pipe<BCodeSchedule>(
                map(itemPicked => itemPicked ? new BCodeSchedule({ scheduleId: itemPicked.id, schedule: itemPicked }) : null)
            );
        }, gridValidator: [percentZeroOrOne]
    });

    bSummaryGrid: GridField = new GridField({
        field: { label: 'Budget History', value: 'budgetSummary', formRow: 2, visible: Field.formOnly, sendServer: false },
        rowFactory: this.bSummaryItemRowFactory.bind(this),
    });

    typeField = FormPicklistComponent.make('Type', 'typeId', 'type', { items: BCode.TYPES, refreshes: ['prepaid'] }, { disable: true });
    prepaidField = FormCheckboxComponent.make('Prepaid', 'prepaid',
        {
            hint: $localize`Are expenses of this category paid in advance.
            If they are when entering purchase invoice you will be required to enter a date range of the period
            to and from that you are purchasing the service for. Once entered, the invoice will be accrued for you
            so the expense will be spread over the service period and not all shown on the transaction date.`,
            formColumn: 2, refresh: this.prepaidRefresh.bind(this)
        });

    fieldSet = new FieldSet({
        fields: [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.nameControl({ validators: [required, maxChars(20)] }),
            FormTextComponent.make('Description', 'description', {
                validators: [maxChars(100)],
                hint: $localize`Description of the type of things that are categorised with this budget code`
            }),
            FormNumberComponent.make('Sort Order', 'sort', {}, {
                validators: [required],
                hint: $localize`Numeric sort order to determine where this item appears in reports`
            }),
            FormCheckboxComponent.make('System', 'system', {
                hint: $localize`Is this a system code which cannot be deleted`, formColumn: 2, disable: true
            }),
            this.typeField,
            FieldMaker.notes({ formColumn: 2 }),
            this.prepaidField,

            this.scheduleGrid,
            this.bSummaryGrid
        ], formLayout: LAYOUT_OPTIONS.threeColOver1,
    });

    configReady = new ReplaySubject<null>(1);
    config = new FormConfig({
        navRoute: BCodePageComponent.navRoute,
        title: $localize`Budget Codes`,
        help: $localize`Budget codes help us to analyse past spending and plan our future spending`,
        fieldSet: this.fieldSet,
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.beforeEdit.bind(this),
        beforeList: this.beforeList.bind(this),
    });

    constructor(public dataSvc: BCodeService,
        public scheduleSvc: ScheduleService,
        public dialog: MatDialog,
        @Optional() public dialogRef: MatDialogRef<UnitPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        super();

        this.scheduleSvc.get(true).pipe(first()).subscribe((data: Schedule[]) => {
            this.allSchedules = data;
            data.forEach(schedule => {
                this.fieldSet.fields.push(FormNumberComponent.make(schedule.name,
                    'schedule' + schedule.id, { format: 'percent', formatParms: '1.3-3', blankZero: true },
                    { name: schedule.name, disable: true, visible: Field.noForm }));
            });
            this.configReady.next(null);
        });
    }

    beforeList(bcodes: BCode[]) {
        console.log(bcodes);
        bcodes.forEach( bc => {
            bc.schedule.forEach ( bcs => {
                bc['schedule' + bcs.scheduleId] = bcs.percent;
            });
        })
        return bcodes;
    }

    beforeEdit(o: BCode): BCode {
        if (o.system) {
            this.typeField.control.disable({ emitEvent: false });
        } else {
            this.typeField.control.enable({ emitEvent: false });
        }
        o.budgetSummary = this.summariseBudgets(o);
        this.prepaidRefresh(o.type);
        console.log(o);
        return o;
    }

    summariseBudgets(o: BCode) {
        const c = [];
        for (const bschedule of o.budgets) {
            let bsummary = c.find(bs => bs.cycleName === bschedule.cycle.name);
            if (bsummary === undefined) {
                bsummary = this.createSummaryLine(bschedule.cycle);
                c.push(bsummary);
            }
            bsummary['schedule' + bschedule.scheduleId] += bschedule.plannedSpend;
        }
        c.forEach((summary) => {
            const prev = c.find(s => s.cycleId === summary.previousId);
            if (prev) {
                for (const s of this.allSchedules) {
                    summary['previous' + s.id] = prev['schedule' + s.id];
                }
            }
        })
        return c;
    }

    createSummaryLine(cycle: Cycle) {
        const newItem = { cycleName: cycle.name, cycleId: cycle.id, previousId: cycle.previousId }
        for (const s of this.allSchedules) {
            newItem['schedule' + s.id] = 0;
            newItem['previous' + s.id] = 0;
        }
        return newItem;
    }

    calcArrow(c, f: ButtonField, s: Schedule) {
        if (c['schedule' + s.id] > c['previous' + s.id]) {
            f.btnOpts.iconStyle = 'color:red; padding-left: 15px'
            return 'arrow_upward';
        } else if (c['schedule' + s.id] < c['previous' + s.id]) {
            f.btnOpts.iconStyle = 'color:green; padding-left: 15px; padding:right: '
            return 'arrow_downward';
        }
        return '';
    }

    bSummaryItemRowFactory() {
        const fields = [
            FormTextComponent.make('Cycle', 'cycleName', { readonly: true }),
        ];
        for (const s of this.allSchedules) {
            fields.push(FormButtonComponent.make('', '', {
                name: 'arrow' + s.id, type: 'icon', sendServer: false, label: '',
                cellOpts: { heading: '', width: '1%', style: 'padding: 0px 0px 0px 0px; ' },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                calculateValue: (o: any, f: ButtonField) => this.calcArrow(o, f, s),
            }));
            fields.push(FormNumberComponent.makeCurrency(s.name, 'schedule' + s.id, { readonly: true, }).blankZero());
            fields.push(FormNumberComponent.makePercent('%', 'previous' + s.id,
                {
                    readonly: true, cellOpts: { style: 'align: right; font: lighter' }, calculateValue: (c) => {
                        if (c['previous' + s.id]) {
                            return (c['schedule' + s.id] - c['previous' + s.id]) / c['previous' + s.id];
                        } else if (c['schedule' + s.id] > 0) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                }).blankZero());
        }
        fields.push(FieldMaker.spacer());
        return fields;
    }

    prepaidRefresh(o: AbstractObject) {
        this.prepaidField.visible = (o.id === BCode.TYPE.EXPENSE.id) ? Field.formOnly : Field.noShow;
    }

    newFactory(): Observable<BCode> {
        this.typeField.control.enable({ emitEvent: false });
        const bc = new BCode();
        if (this.dialogOptions) {
            bc.name = this.dialogOptions['name'];
            bc.sort = this.dialogOptions['sort'];
        }
        return of(bc);
    }
}
