import { Observable, of } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { Txn } from "src/app/model/txn";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { InvoiceService } from "../invoice.service";
import { TeamUserService } from "../../user/team-user-page/team-user.service";
import { HttpParams } from "@angular/common/http";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { FieldSet, LAYOUT_OPTIONS } from "src/app/shared/form/field-set/field-set.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { TeamUser } from "src/app/model/team-user";
import { FieldSetDialogComponent, FieldSetDialogOptions } from "src/app/shared/dialogs/field-set-dialog/field-set-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { concatMap, map } from "rxjs/operators";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { Field } from "src/app/shared/field/Field";
import { MailItem } from "src/app/model/mailMerge";

export class MailInvoicePDFAction implements IFormAction {
    name = $localize`Mail PDF to Unit Owner`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'mail';
    approvalNeeded = false;
    disabled = false;
    approvalText: string;
    tipText: string;
    txn: Txn;

    invoiceSvc = MyInjector.instance.get(InvoiceService)
    teamSvc = MyInjector.instance.get(TeamUserService);
    cds = MyInjector.instance.get(ConfirmDialogService);

    action() {
        const hp = new HttpParams().set('unitId', this.txn.unitId).set('isOwner', true);
        return this.teamSvc.get<TeamUser>(false, hp, this.txn.teamId).pipe( concatMap(
            owners => {
                const ownersWithEmails = owners.filter(o => o.email);
                if (owners.length > 0 && ownersWithEmails.length > 0) {
                    return this.openSendMailDialog(owners);
                } else {
                    
                    return this.cds.alert('Cannot send email','There are no owners with email address').afterClosed().pipe(
                        map(() => this.txn)
                    );
                }
            }
        ))
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(txn: Txn) {
        this.show = true;
        this.txn = txn;
    }

    sendTheMail(sendTo: TeamUser) : Observable<Txn>{
        console.log(sendTo);
        if (!sendTo.email) {
            this.cds.alert('Cannot send', `${sendTo.fullName} has no email address`);
            return of(this.txn);
        }
        return this.invoiceSvc.getPDF(this.txn, true, sendTo.personId).pipe( map(response => {
            if (response) {
                this.txn.mailItems.push(response as unknown as MailItem);
            }
            return this.txn;
        }));
    }

    openSendMailDialog(owners: TeamUser[]) {
        const dialog = MyInjector.instance.get(MatDialog);
        const toAddress = FormTextComponent.make('Email Address', 'email', {disable: true});
        const toName = FormTextComponent.make('Name', 'fullName', {visible: Field.noShow});
        const personPicker = FormPicklistComponent.make('Send To', 'personId', null, 
            {
                items: owners, 
                optionValue: (o: TeamUser) => o.personId,
                optionDisplayValue: (o:TeamUser) => o.fullName,
                refreshes: [ (ot: TeamUser) => {
                    toAddress.control.setValue(ot.email);
                    toName.control.setValue(ot.fullName);
                }]
            }
        );
        const fieldSet = new FieldSet({
            fields: [
                personPicker,
                toAddress,
                toName,
            ],
            formLayout: LAYOUT_OPTIONS.simpleDialog
        })

        fieldSet.setValue(owners[0]);
        const data = {
            fieldSet,
            saveFn: this.sendTheMail.bind(this),
            saveText: 'Send',
            title: 'Select owner to email invoice to',
        } as FieldSetDialogOptions

        return dialog.open(FieldSetDialogComponent, {data} ).afterClosed();
    }    
}
