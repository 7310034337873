/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { uuid } from 'src/app/model/abstract-object';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { map } from 'rxjs/operators';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RequestService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/requests';
    protected cache: ServiceRequest[];
    protected typeString = 'ServiceRequest';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getOpen() {
        return this.get(false, new HttpParams().set('srStatus', 'open'));
    }

    /*
    getOmcOpen(omcId: uuid) {
        return this.get(false, new HttpParams().set('srStatus', 'open').set('omcId', omcId));
    }
*/

    refreshParents(omcId: uuid, parentField: PicklistField) {
        parentField.picklist.items.length = 0;
        this.get(false, new HttpParams().set('srStatus', 'open').set('omcId', omcId)).subscribe(open => {
            open.forEach(sr => parentField.picklist.items.push(sr));
        });
    }

    closeRequest(sr: ServiceRequest, status: string, comt: string) {
        const url = `${this.baseUrl}/${sr.id}/close`;
        const data = {requestId: sr.id, status, comt};
        return this.http.post(url, data, this.getSimpleOptions(sr.teamId)).pipe( map((r: ServiceRequest) => {
            this.updateCachedItem(r);
            return r;
        }));
    }

    reopenRequest(sr: ServiceRequest, reason: string) {
        const url = `${this.baseUrl}/${sr.id}/reopen`;
        const data = { requestId: sr.id, reason };
        return this.http.post(url, data, this.getSimpleOptions(sr.teamId)).pipe(map((r: ServiceRequest) => {
            this.updateCachedItem(r);
            return r;
        }));
    }

    acceptRequest(sr: ServiceRequest) {
        const url = `${this.baseUrl}/${sr.id}/accept`;
        const data = { requestId: sr.id };
        return this.http.post(url, data, this.getSimpleOptions(sr.teamId)).pipe(map((r: ServiceRequest) => {
            this.updateCachedItem(r);
            return r;
        }));
    }

    getPrintPDF(sr: ServiceRequest, content: string) {
        const url = `${this.baseUrl}/${sr.id}/printPDF`;
        const data = { requestId: sr.id, content };
        const params = {} as Params;
        this.http.post(url, data,{
            params, headers: { 'Accept': 'application/octet-stream' },
            observe: 'response',
            responseType: 'blob'
        }).subscribe(
            (success) => this.doResponseDownload(success),
            (failure) => this.messageService.showError(failure, 'Getting Print PDF')
        );
    }

    sendSupplierMails(sr: ServiceRequest, content: string, suppliers: {id: uuid}[]) {
        const url = `${this.baseUrl}/${sr.id}/sendSuppliers`;
        const data = { requestId: sr.id, content, suppliers };
        return this.http.post(url, data, this.getSimpleOptions(null));
    }
}
