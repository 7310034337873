/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
* Describes a numeric field to be displayed on a Form, Grid or Table
*/
import { formatCurrency, formatNumber, formatPercent } from "@angular/common";
import { CellOpts, Field, FieldType } from 'src/app/shared/field/Field';


export class NumberOpts {
    format?: 'decimal' | 'percent' | 'currency' | 'plain';
    formatParms?: string;
    width?: number;
    step? = 1;
    blankZero?: boolean
}


export class NumberField extends Field {

    type: FieldType = 'number';

    numberOpts: NumberOpts = { format: 'decimal' }; //new NumberOpts();

    cellOpts: CellOpts = { style: 'justify-content: flex-end; text-align: right; padding-right: 5px' };
    footer: { style: 'justify-content: flex-end; text-align: right; padding-right: 5px' };

    constructor(defaultOptions: Partial<NumberField>, moreOptions: Partial<NumberField> = {}) {
        super(defaultOptions, moreOptions);
        if (moreOptions.cellOpts) {
            this.cellOpts = { ...this.cellOpts, ...moreOptions.cellOpts };
        }
        this.defaultLabels();
    }

    getStringNumericValue(val: string) {
        return +val.replace(/[^\d.-]/g, '');
    }
    blankZero() {
        this.numberOpts.blankZero = true;
        return this;
    }

    formatValue(val) {
        if (this.numberOpts.blankZero && (!val)) {
            return '';
        } else if (this.numberOpts.format === 'percent') {
            return formatPercent(val, Field.getLang(), this.numberOpts.formatParms);
        } else if (this.numberOpts.format === 'decimal') {
            return formatNumber(val, Field.locale, this.numberOpts.formatParms);
        } else if (this.numberOpts.format === 'currency') {
            return formatCurrency(val, Field.locale, Field.currencySymbol, Field.currencyCode, this.numberOpts.formatParms);
        }
        return super.formatValue(val);
    }
}
