@if (ballot) {
  <div class="basePage">
    <ng-container *ngTemplateOutlet="bannerTemplate"></ng-container>
    <div style="display:flex; flex-wrap:wrap ;">
        <div  style="flex: 1; margin: 0px 3px 0px">
            <ng-container *ngTemplateOutlet="ballotCard"></ng-container>
            <ng-container *ngTemplateOutlet="attachmentCard"></ng-container>
            <ng-container *ngTemplateOutlet="requestTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="commentTemplate"></ng-container>
        </div>
        <div style="flex: 1; margin: 0px 3px 0px;">
            <ng-container *ngTemplateOutlet="questionCard"></ng-container>
        </div>
    </div>
  </div>
}

<ng-template #bannerTemplate>
    <div style="display:flex; width: 100%">
        <div style="flex: 20">
            <h1 class="mat-h1" (click)="goToList()" matTooltip="Click to return to list of items" style="cursor: pointer;">
                <span class="primary-font-color">{{currentTeamName}} {{currentTeamName ? ' : ' : ''}}
                    Voting & Meetings </span>
            </h1>
        </div>
        @if (!isNarrow && !printMode) {
            <div style="flex: 10; text-align: right">
                @if (ballot.mailsSent === 0) {
                <button mat-raised-button color="accent" (click)="sendNotices()">
                    Send Notifications
                </button>
                }
                @if (ballot.mailsSent > 0) {
                <button mat-raised-button disabled="true">
                    {{ballot.mailsSent}} Notifications Sent {{ballot.notificationSentAt | fromNow}}
                </button>
                }
                @if (ballot.mailsSent < 0) { <button mat-raised-button disabled="true">
                    Sending Notifications...
                    </button>
                    }
            </div>
        }
    </div>
</ng-template>

<ng-template #ballotCard>
    <mat-card appearance="raised" style="margin-bottom: 10px">
        <mat-card-content>
            <div style="display:flex;">
                <div>
                    <h2 class="mat-h2">{{ballot.title}}</h2>
                </div>
                @if (!printMode) {
                    <button mat-icon-button color="primary" style="margin-top: -12px" (click)="editBallot()">
                        <mat-icon class="big">edit</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" style="margin-left: -10px; margin-top: -12px" (click)="showHistory()"
                        matTooltip="Show history of changes">
                        <mat-icon class="big">history</mat-icon>
                    </button>
                    <!-- Print messes up, timing errors reformatting charts etc, cannot reprint while Settling, maybe a print view
                    <button (click)="print()" style="margin-left: -10px; margin-top: -12px" mat-icon-button color="primary">
                        <mat-icon class="big">print</mat-icon>
                    </button>
                    -->
                }
            </div>
            <div style="display:flex; margin-bottom: 6px">
                <div style="flex:1">
                    <p class="ballotSubtitle">{{ballot.forum?.name}}</p>
                    <p class="ballotSubtitle">{{ballot.areaName}} {{ballot.coreName}}</p>
                </div>
                <div style="flex:1; text-align: right;">
                    <p class="ballotSubtitle">Starting: {{dateHelper.localDateTime(ballot.opensAt)}}</p>
                    <p class="ballotSubtitle">Ending: {{dateHelper.localDateTime(ballot.closesAt)}}</p>
                </div>

            </div>
            <div [innerHTML]="ballot?.content"></div>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #attachmentCard>
    @if (attachmentGrid.visible.form) {
        <mat-card appearance="raised" style="margin-bottom: 10px">
            <mat-card-content>
                <app-ctl-holder [control]="attachmentControl"></app-ctl-holder>
            </mat-card-content>
        </mat-card>
    }
</ng-template>

<ng-template #commentTemplate>
    <table style="margin-bottom: 20px">
        <tr>
            @if (ballot.comments?.length === 0 && canComment && !printMode) {
                <td>
                    <button mat-raised-button color="primary" (click)="viewComments()">
                        Add Comment
                    </button>
                </td>
            }
            @if (ballot.comments?.length > 0) {
            <td>
                <button mat-raised-button color="accent" (click)="viewComments()">
                    View {{ballot.comments.length}} Comments
                </button>
            </td>
            }
        </tr>
    </table>
</ng-template>
<ng-template #requestTemplate>
    @if(showRequests) {
        <mat-card appearance="raised" style="margin-bottom: 10px">
            <mat-card-content>
            <div style="margin-top: -20px">
                <p style="padding-top:15px; opacity: .75; font-size: small">Related Requests</p>
            </div>
            <ul style="margin-top: -10px">
                @for (r of ballot.requests; track $index) {
                <li><a [routerLink]="getRequestRoute(r)">{{r.refNr}}</a> ({{r.srStatus}}) {{r.title}}</li>
                }
            </ul>
            <div style="text-align: center;">
                @if(allowEdit && !isNarrow && !printMode) {
                    <button mat-icon-button adder class="add-button" (click)="addRequest()"
                    matTooltip="Add a service request">
                        <mat-icon color="primary">add_circle</mat-icon>
                    </button>
                }
            </div>

            </mat-card-content>
        </mat-card>
    }
</ng-template>

<ng-template #questionCard>
    <mat-card appearance="raised">
        <mat-card-content>
            @if (!printMode) {
                <h2 class="mat-h2">Ballot Items</h2>
            }
            <ng-container *ngTemplateOutlet="questions"></ng-container>
            <div style="text-align: center; margin-top: 10px" matTooltip="Add another question that can be voted on">
                @if (questionPrivs.post && allowEdit && ballot.closesAt * 1000 > DateNow.now() && !isNarrow && !printMode) {
                <button mat-icon-button adder class="add-button" style="margin-top: -8px"
                    (click)="editQuestion($event, null)">
                    <mat-icon color="primary">add_circle</mat-icon>
                </button>
                }
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #questions>
  <mat-accordion cdkDropList  (cdkDropListDropped)="dropQuestion($event)" cdkDropListData="ballot?.questions">
    <!-- mat-card style="margin-bottom: 20px; padding:0px">
    <mat-card-content   -->
      @for (question of ballot?.questions; track question; let colIndex = $index) {
        <div class="accordion-item" style="break-before: page;">
        <mat-expansion-panel
          (opened)="selectQuestion(question)" (closed)="unselectQuestion(question)"
          [expanded]="printMode || (selectedQuestion === question)" hideToggle
          cdkDrag [cdkDragData]="question" cdkDragBoundary="mat-accordion"
          [cdkDragDisabled]="ballot.closesAt * 1000 < DateNow.now() || selectedQuestion !== null || !questionPrivs.put || !allowEdit  || isNarrow">

          <mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="questionHeader; context: {question, selectedQuestion}"></ng-container>
          </mat-expansion-panel-header>

          @if (questionPrivs.put && allowEdit && !isNarrow && !printMode) {
            <ng-container *ngTemplateOutlet="questionButtons; context: {question}"></ng-container>
          }

          <div class="questionContent" [innerHTML]="question?.content"></div>
          <div style="width: 100%; display: flex; flex-wrap: wrap;">
            <!-- app-chart [control]="question.resultControl"></app-chart -->
            <div style="flex: 1; min-width: 300px;">
              <ng-container *ngTemplateOutlet="voteCastTemplate; context: {question}"></ng-container>
              <ng-container *ngTemplateOutlet="options; context: {question}"></ng-container>
            </div>
            <div style="display:flex">
                @if ((true || voteClosed(question) || (allowEdit && !isNarrow))) {
                    <div style="flex: 1; align-items: center;">
                      <h4 style="text-align: center; margin-bottom: 0px">Results</h4>
                      <div style="margin-top: -15px" id="results-{{question.id}}" class="resultsGraph"></div>
                    </div>
                  }
                  <div style="flex: 1;">
                    <ng-container *ngTemplateOutlet="questionInfo; context: {question}"></ng-container>
                  </div>
            </div>
            @if (question.attachments?.length > 0 || (!printMode && questionPrivs.put && allowEdit && question.closesAt * 1000 > DateNow.now())) {
              <div
                style="flex-basis: 100%; margin-left:40px; margin-right: 40px;">
                <app-ctl-holder [control]="questionAttachmentControl"></app-ctl-holder>
              </div>
            }
          </div>
        </mat-expansion-panel>
    </div>
      }
    <!-- /mat-card-content></mat-card-->
  </mat-accordion>
</ng-template>
<ng-template #questionHeader let-question="question" let-selectedQuestion="selectedQuestion">
  <mat-panel-title>
    <div style="display:flex; align-items:center; width: 100%">
        <div>
            <mat-icon color="primary" style="font-size: 32px; padding-bottom:10px; padding-right:10px">
                {{question === selectedQuestion ? 'chevron_right' : 'expand_more'}}
            </mat-icon>
        </div>
        <div style="flex: 1; overflow:hidden; text-overflow: ellipsis;"
        [style.max-height]="question === selectedQuestion ? null : '2.8em'">
            <span matTooltip="Click to expand and see full details of question">{{question.question}}</span>
        </div>
        @if (selectedQuestion === null) {
            <ng-container *ngTemplateOutlet="questionOpenIndicator; context: {question}"></ng-container>
        }
        @if (selectedQuestion === null && ballot.closesAt * 1000 > DateNow.now() && !isNarrow) {
            <div>
                <mat-icon color="primary"  matTooltip="Drag and drop to change position">drag_indicator</mat-icon>
            </div>
        }

    </div>

  </mat-panel-title>
</ng-template>

<ng-template #questionOpenIndicator let-question="question">
    <div [matTooltip]="'Opens: ' + formatUnixtime(question.opensAt) + ' Closes : ' + formatUnixtime(question.closesAt)">
        @if (voteClosed(question)) {
          <mat-icon color="warn" matTooltip="Vote was closed {{question.closesAt * 1000 | date}}">https</mat-icon>
        }
        @if (voteIsOpen(question)) {
          <mat-icon color="primary" matTooltip="Vote is open {{question.opensAt * 1000 | date}}">lock_open</mat-icon>
        }
        @if (!voteOpened(question)) {
          <mat-icon color="accent" matTooltip="Vote not open yet">hourglass_top</mat-icon>
        }
    </div>
</ng-template>
<ng-template #questionButtons let-question="question">
    <div style="display:flex; position: relative; top: -65px; float: right">
            <div class="editButton">
              <a matTooltip="Click to show votes cast"
                class="iconButton" (click)="showVotes($event, question)">
                <mat-icon color="primary">poll</mat-icon>
              </a>
              @if (!voteOpened(question)) {
                <a matTooltip="Click to allow voting to commence"
                  class="iconButton" (click)="openVoting($event, question)">
                  <mat-icon color="primary">start</mat-icon>
                </a>
              }
              @if (voteIsOpen(question)) {
                <a matTooltip="Click to close the voting now"
                  class="iconButton"  (click)="closeVoting($event, question)">
                  <mat-icon color="primary">cancel_presentation</mat-icon>
                </a>
              }
              @if (question.opensAt * 1000 > DateNow.now()) {
                <a mat-icos-button matTooltip="Click to edit question"
                  class="iconButton" (click)="editQuestion($event, question)">
                  <mat-icon color="primary">edit</mat-icon>
                </a>
              }
              @if (question.opensAt * 1000 < DateNow.now()) {
                <a mat-icos-button matTooltip="Cannot edit question after voting opened"
                  class="iconButton">
                  <mat-icon>edit</mat-icon>
                </a>
              }
              @if (ballot.closesAt * 1000 > DateNow.now()) {
                <a mat-icos-button matTooltip="Click to change start and end times"
                  class="iconButton" (click)="editQuestion($event, question, true)">
                  <mat-icon color="primary">timer</mat-icon>
                </a>
              }
              <a mat-icos-button matTooltip="Show History" class="iconButton"
                (click)="showQuestionHistory(question)">
                <mat-icon color="primary">history</mat-icon>
              </a>
              @if (ballot.closesAt * 1000 > DateNow.now()) {
                <a mat-icos-button matTooltip="Delete this question"
                  class="iconButton" (click)="deleteQuestion($event, question)">
                  <mat-icon color="primary">delete</mat-icon>
                </a>
              }
              <!-- a mat-icos-button matTooltip="Drag and drop to change position" class="iconButton" *ngIf="selectedQuestion === null" (click)="">
              <mat-icon color="primary"
              cdkDragRootElement="mat-expansion-panel" cdkDrag cdkDragHandle cdkDragBoundary="mat-accordion">drag_handle</mat-icon>
              </a -->
            </div>
    </div>
</ng-template>

<ng-template #questionInfo let-question="question">
  @if (isNarrow === false) {
    <!-- div class="questionContent">
      {{voteStatus(question)}}
    </div -->
  }
  <div style="font-size: small; text-align: right; margin-right: 40px">
    <p>
      <span class="infoLabel">Voting Open: </span>
      <span class="info">{{formatUnixtime(question.opensAt)}}</span>
    </p>
    <p>
      <span class="infoLabel">Voting Close: </span>
      <span class="info">{{formatUnixtime(question.closesAt)}}</span>
    </p>
    @if (question.proposer) {
      <p>
        <span class="infoLabel">Proposed By: </span>
        <span class="info">{{Person.fullName(question.proposer)}}</span>
      </p>
    }
    @if (question.seconder) {
      <p>
        <span class="infoLabel">Seconded By: </span>
        <span class="info">{{Person.fullName(question.seconder)}}</span>
      </p>
    }
    <!-- div style="flex: 1; margin-left: 40px;">
  </div>
  <div class="proposer" style="flex: 1">
    </div -->
  </div>
</ng-template>
<ng-template #options let-question="question">
  <div class="questionOptions">
    <mat-radio-group class="example-radio-group"
      (change)="castVote($event, question)"
      [(ngModel)]="question.votedOptionId">
      <!-- [(ngModel)]="question.vote"-->
      @for (option of question.options; track option) {
        <mat-radio-button class="example-radio-button"
          [value]="option.id" color="primary" [disabled]="!voteIsOpen(question) || !canVote">
          <span  style="white-space: normal">
            <span class="optionName">{{option.name}}</span>
            @if (option.description) {
              <span> - </span>
            }
            <span class="optionDescription">{{option.description}}</span>
            @if (voteClosed(question)) {
              <span>
                ({{option.votes}} vote(s) received)
              </span>
            }
          </span>
        </mat-radio-button>
      }
    </mat-radio-group>

    @if (canVote && (!question.votesCast || question.votesCast.length === 0)) {
      Click option above to vote
    }
    @if (!canVote && voteIsOpen(question)) {
        You do not have voting rights in {{ballot.forum.name}}
    }
  </div>
</ng-template>

<ng-template #voteCastTemplate let-question="question">
    <h3 class="mat-h3" style="margin-bottom: 0px; margin-left: 40px">
        @if (!voteClosed(question)) {
            @if (canVote) {
                Please click on option below to cast your vote
            } @else {
                Options for voters to choose
            }
        } @else {
            Ballot closed
        }
    </h3>
    <p class="voteComment">
        @if (question.votesCast.length === 0) {
            <span>You did {{canVote ? 'did': 'could'}} not vote</span>
        } @else if (question.votesCast.length > 0) {
            @if (question.votesCast.length === 1) {
                <span>Your vote was cast {{question.voteCastOn}}</span>
            }
            @if (question.votesCast.length > 1) {
                <span>Your {{question.votesCast.length}} votes where cast {{question.voteCastOn}}</span>
            }
            @if (question.voteCastById) {
                <span> by <app-avatar [id]="question.voteCast.userId"> </app-avatar> </span>
            }
        }
    </p>
</ng-template>
