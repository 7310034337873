
<fieldset style="border: 1px solid; font-family: roboto-flex; border-radius: 5px; margin-right: 10px; min-height: 55px;">
  @if (!isPhone) {
    <legend style="font-size: small; font-weight: lighter">
      These users are following conversation and receive email updates
    </legend>
  }
  @if (isPhone) {
    <legend style="font-size: small; font-weight: lighter">Followers</legend>
  }
  <mat-chip-listbox aria-label="Followers">
    @for (follower of chatField.chat?.followers; track $index) {
      <mat-chip  (removed)="remove(follower)">
         {{follower.personFullName}}
        @if (allowRemove) {
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        }
      </mat-chip>
    }
  </mat-chip-listbox>
</fieldset>
