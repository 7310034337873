/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { MyInjector } from "src/app/app.module";
import { EditDialogComponent } from "src/app/shared/dialogs/edit-dialog/edit-dialog.component";
import { RequestService } from "../request.service";
import { RequestBudget, ServiceRequest } from "src/app/model/serviceRequest";
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from "src/app/shared/grid/grid-field";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { BCode } from "src/app/model/bcode";
import { Schedule } from "src/app/model/schedule";
import { AgentDataService } from "../../agent/agent-data.service";
import { of } from "rxjs";
import { FieldSet, LAYOUT_OPTIONS } from "src/app/shared/form/field-set/field-set.component";
import { MatDialog } from "@angular/material/dialog";
import { SupplierService } from "../../supply/supplier.service";
import { FieldMaker } from "src/app/shared/field/FieldMaker";
import { required } from "src/app/shared/validators";

export class RequestBudgetEditor {
    injector = MyInjector.instance;
    dialog = this.injector.get(MatDialog);
    requestService = this.injector.get(RequestService);
    agentDataSvc = this.injector.get(AgentDataService);
    supplierSvc = this.injector.get(SupplierService);


    bCodes: BCode[] = [];
    schedules: Schedule[] = [];
    sr: ServiceRequest;

    supplierField = ServiceRequest.getSupplierField();

    getConfig() {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    FormTextComponent.make('Request Title', 'title', {readonly: true}),
                    this.supplierField,
                    new GridField({
                        field: { label: 'Budget', value: 'requestBudget'},
                        rowFactory: () => [
                            FieldMaker.id(),
                            FieldMaker.rev(),
                            FieldMaker.idHolder('teamId'),
                            FormPicklistComponent.make('Budget Code', 'bCodeId', null, {items: this.bCodes}, {validators: [required]}),
                            FormPicklistComponent.make('Schedule', 'scheduleId', null, { items: this.schedules }, {validators: [required]}),
                            FormNumberComponent.makeCurrency('Amount', 'amount'),
                            FieldMaker.deleteGridRow()
                        ],
                        objFactory: this.newRequestBudgetItem.bind(this),
                    })
                ],
                formLayout: LAYOUT_OPTIONS.simpleDialog
            }),
            mode: 'edit',
            title: $localize`Work Order Information`
        })
    }

    editBudget(sr: ServiceRequest) {
        this.sr = sr;
        this.supplierSvc.get(true).subscribe( supps => {
            this.supplierField.setPicklistItems(supps);
        })
        this.agentDataSvc.getOmcExpenseBCodes(sr.teamId).subscribe( bcodes => {
            this.bCodes.length = 0;
            bcodes.forEach( bc => this.bCodes.push(bc))
        });
        this.agentDataSvc.getOmcSchedules(sr.teamId).subscribe(scheds => {
            this.schedules.length = 0;
            scheds.forEach(s => this.schedules.push(s))
        });

        return this.dialog.open(EditDialogComponent,
            {
                data: {
                    config: this.getConfig(),
                    id: sr.id,
                    service: this.requestService,
                    hideTabs: true,
                    width: 660,
                }
            }
        ).afterClosed();
    }

    newRequestBudgetItem() {
        const item = new RequestBudget();
        if (this.schedules.length === 1) {
            item.scheduleId = this.schedules[0].id;
        }
        item.teamId = this.sr.teamId;
        return of(item)
    }
}
