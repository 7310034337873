/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';

export class CommsTemplateType extends AbstractObject {
    title: string;
    replacements: string[];

    static BALLOT = { id: 'ballot', name: 'Ballot' } as CommsTemplateType;

    static TEAMVALUES = ['currentDate', 'paymentTerms', 'legalName', 'logo', 'omcName', 'omcEmailAddress', 'agentName', 'billingAddress'];

    static BILLING = {
        id: 'billing', name: 'Billing', replacements: [
            'fullName', 'address', 'transactionDate', 'financialYear', 'unitName', 'refNr', 'debit',
            'outstanding', 'unitBalance',
            ... this.TEAMVALUES
        ]
    } as CommsTemplateType;

    static DOCUMENT = { id: 'invoice', name: 'Document' } as CommsTemplateType;

    static INVOICE = { id: 'invoice', name: 'Invoice', replacements: CommsTemplateType.BILLING.replacements } as CommsTemplateType;

    static PERSON = { id: 'person', name: 'Person', replacements: [
        'fullName', 'address', 'unitName', 'outstanding',
        ... this.TEAMVALUES
    ] } as CommsTemplateType;

    static POST = { id: 'post', name: 'Post' } as CommsTemplateType;

    static REQUEST = { id: 'request', name: 'Request', replacements: [
        'refNr', 'title', 'description', 'priority', 'owner',
        'unitName', 'unitAddress',
        'personFullName', 'personPhone',
        ... this.TEAMVALUES
    ] } as CommsTemplateType;

    static STATEMENT = { id: 'statement', name: 'Statement' } as CommsTemplateType;

    static QUESTION = { id: 'question', name: 'Question' } as CommsTemplateType;

    static TYPES: CommsTemplateType[] = [
        CommsTemplateType.BALLOT,
        CommsTemplateType.BILLING,
        CommsTemplateType.DOCUMENT,
        CommsTemplateType.INVOICE,
        CommsTemplateType.PERSON,
        CommsTemplateType.POST,
        CommsTemplateType.REQUEST,
        CommsTemplateType.STATEMENT,
        CommsTemplateType.QUESTION
    ];
}
export class CommsTemplate extends AbstractObject {
    title: string;
    content: string;
    parentId: uuid;
    type: uuid;

    constructor(o: Partial<CommsTemplate> = {}) {
        super(o);
    }

}
