/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { Params } from '@angular/router';
import { FieldSet } from './field-set/field-set.component';
import { NavOption } from 'src/app/model/sys/navOption';
import { ITabField } from '../field/TabFIeld';
import { ChartAnalysis } from '../chart/chart-analysis';
import { FormMode, IFormAction } from './form.component';
import { HttpParams } from '@angular/common/http';
import { NavRoute } from '../NavRoute';
import { PicklistField } from '../field/PicklistField';


export class FormConfig {
    fieldSet: FieldSet;
    service?: AbstractHttpService;
    title: string;
    forTeamName?: string;
    //navItem: NavItem;
    navRoute: NavRoute;
    help: string;
    mode: FormMode;
    forceMode: FormMode;
    tabFields: ITabField[] = [];
    readonly = false;
    allowDelete = true;
    deleteMsg: string|null = null;
    deleteReasonNeeded: boolean = false;
    allowEdit = true;
    allowNew = true;
    objectFactory?: (queryParams: Params) => Observable<AbstractObject>;
    itemId?: uuid;
    itemTitle?: string;
    customTitle?: string;
    itemTitleGetter?: (o:AbstractObject) => string;
    actions?: IFormAction[] = [];
    newOptions?: NavOption[] = [];
    initNotification?: Subject<UntypedFormGroup>;
    /** Fired by form when it receives the configReady signal */
    isReady: ReplaySubject<null> = new ReplaySubject(1);
    forceTeamSwitchOnDrilldown? = false;
    /**
     * Override this to defer form initialization until you have finished prepping the configuation
     * Call configReady.next() on the object you have passed to signal that initialization may continue.
     * */
    configReady: ReplaySubject<null> = new ReplaySubject(1);
    charts: ChartAnalysis; // = new ChartAnalysis();

    constructor(config: Partial<FormConfig>) {
        Object.getOwnPropertyNames(config).forEach((prop) => { this[prop] = config[prop]; });
        if (!config.configReady) {
            this.configReady.next(null); // Fire config ready, so when subscribed to it can go ahead and initialize
            this.configReady.complete();
        }
        if (config.isReady) { // Internal event, should not be accessed directly
            console.error('You should not pass isReady to a form, you probably meant to pass configReady');
        }
        this.configReady.subscribe(() => {
            this.isReady.next(null);
            this.isReady.complete();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeList(data: AbstractObject[], params: HttpParams) {
        return data;
    }

    beforeEdit(o): AbstractObject {
        return o;
    }

    pathEdit(o: AbstractObject) {
        //console.warn('Does it object to being prefixed with a slash');
        return this.navRoute.getIdUrl(o.id);
    }

    /*
    * Override to provide functionality to execute when parameters are set.
    */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    setTeam( teamId: uuid) {
    }

    tableHighlighter: (o: AbstractObject) => boolean;

    pathNew() {
        return this.navRoute.newUrl;
    }

    hasPersonName(item) {
        if (Object.prototype.hasOwnProperty.call(item, 'title')
        && Object.prototype.hasOwnProperty.call(item, 'lastName')
        && Object.prototype.hasOwnProperty.call(item, 'firstName')) {
            return true;
        }
        return false;
    }

    getPersonName(item) {
        let name = '';
        if (item.title) {
            name = item.title + ' ';
        }
        if (item.firstName) {
            name += item.firstName + ' '
        }
        if (item.lastName) {
            name += item.lastName;
        }
        return name;
    }

    setItemTitle(item: AbstractObject) {

        if (!item) {
            this.itemTitle = 'Not Found';
        } else if (this.itemTitleGetter) {
            this.itemTitle = this.itemTitleGetter(item);
        } else if (this.hasPersonName(item)) {
            this.itemTitle = this.getPersonName(item);
        } else if (Object.prototype.hasOwnProperty.call(item, 'title')) {
            this.itemTitle = item['title'];
            if (Object.prototype.hasOwnProperty.call(item, 'refNr')) {
                this.itemTitle += ' (' + item['refNr'] + ')';
            }
        } else if (Object.prototype.hasOwnProperty.call(item, 'name')) {
            this.itemTitle = item['name'];
            if ( Object.prototype.hasOwnProperty.call(item, 'refNr')) {
                this.itemTitle += ' (' + item['refNr'] + ')';
            }
        } else if (Object.prototype.hasOwnProperty.call(item, 'refNr')) {
            this.itemTitle = item['refNr'];
        //} else if (Object.prototype.hasOwnProperty.call(item, 'id')) {
        //    this.itemTitle = '' + item['id'];
        } else {
            this.itemTitle = '';
        }
        if (this.forTeamName) {
            this.itemTitle = this.itemTitle + ' (' + this.forTeamName + ')';
        }
    }

    getItemTitle() {
        if (this.mode === 'new') {
            return 'New';
        } else {
            return this.itemTitle;
        }
    }

    /** Pass alternative function to be executed before the save starts */
    beforeSave: (formGroup: UntypedFormGroup) => void;


    /** Pass an alternative function to be executed after the form has been saved and the response has been loaded */
    afterSave: (formGroup: UntypedFormGroup) => void;

    /*
    * Pass a function that returns a prompt for the user to confirm saving a record.
    * If the function returns null, save will continue without prompting user
    * If string is returned, user is prompted with the message to confirm save.
    */
    getConfirmSaveMessage: (formGroup: UntypedFormGroup) => string;

    getTitle() {
        let title = this.title;

        if (this.customTitle) {
            title = this.customTitle;
        } else if (this.mode === 'new') {
            title = 'New ' + this.title;
        } else if (this.mode === 'edit') {
            title = 'Edit ' + this.title;
        }
        if (this.forTeamName) {
            title = title + ' (' + this.forTeamName + ')';
        }

        return title;
    }

    populatePicklists(o: AbstractObject[]) {
        const selfPoppers = this.fieldSet.fields.filter(f => f instanceof PicklistField && f?.picklist?.selfPopulate) as PicklistField[];
        selfPoppers.forEach( sp => sp.picklist.items.length = 0)
        o.forEach(o => this.populatePLs(selfPoppers, o));
    }

    private populatePLs(selfPoppers : PicklistField[], o: AbstractObject) {
        selfPoppers.forEach ( f => {
            const id = f.getValue(o);
            if (id) {
                const idx = f.picklist.items.find(i => i.id === id);
                if (!idx) {
                    f.picklist.items.push({ id, name: f.resolveValue(f.picklist.valueObjectName, o), count: 1 } as AbstractObject);
                } else {
                    idx['count'] += 1;
                }
            }
        });
    }
}
