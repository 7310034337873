/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { RecurringJobService } from '../recurring-job/recurring-job.service';
import { HttpParams } from '@angular/common/http';
import { RecurringJob } from 'src/app/model/recurring-job';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { DateHelper } from 'src/app/shared/dateHelper';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { maxValue, minValue, required } from 'src/app/shared/validators';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { Field } from 'src/app/shared/field/Field';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { OmcRole } from 'src/app/model/OmcRole';
import { Omc } from 'src/app/model/Omc';
import { CurrentUserService } from '../../user/current-user.service';
import { UserService } from '../../user/user.service';
import { User } from 'src/app/model/user';
import { RequestPrioritiesService } from '../request-priorities.service';
import { RequestPriority } from 'src/app/model/RequestPriority';
import { RequestCategory } from 'src/app/model/RequestCategory';
import { RequestCategoriesService } from '../request-categories.service';
import { RequestService } from '../request.service';
import { Router } from '@angular/router';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { MatButtonModule } from '@angular/material/button';
import { FieldSetComponent } from '../../../shared/form/field-set/field-set.component';
import { MatStepperModule } from '@angular/material/stepper';

export class StepConfig {
    title: string;
    help: string;
    fieldSet: FieldSet;
    button?: {label: string, action: () => void, disable: boolean}
}

export class ParmsConf extends AbstractObject {
    perDay: number;
    templateName: string;
    startDt: string; // date
    orderBy: string;
    ascending: string;
    days: DayDef[] = []
}
class DayDef {
    dayDt: string;
    items: number;
    include: boolean;
}

@Component({
    selector: 'app-schedule-jobs-stepper',
    templateUrl: './schedule-jobs-stepper.component.html',
    styleUrls: ['./schedule-jobs-stepper.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatStepperModule, FormsModule, ReactiveFormsModule, FieldSetComponent, MatButtonModule]
})
export class ScheduleJobsStepperComponent implements OnInit {

    width = 800
    height = 1200;

    template: RequestTemplate;

    jobsList: RecurringJob[] = [];

    readonly templateField = FormTextComponent.make('Template', 'templateName', { disable: true });

    readonly numberOpts = { width: 3, formatParms: '1.0-0', step: 5 };

    readonly perDay = FormNumberComponent.make('Requests Per Day Per User', 'perDay', this.numberOpts, {
        validators: [required, maxValue(100), minValue(1)],
        valueChanges: this.updateParms.bind(this)
    });

    readonly startDt = FormDateComponent.make('Start Date', 'startDt', {
        validators: [required], valueChanges: this.updateParms.bind(this)
    });
    readonly endDt = FormDateComponent.make('End Date', 'endDt', {disable: true});

    readonly orderOptions: AbstractObject[] = [
        { id: 'units', name: $localize`Number of units` },
        { id: 'alpha', name: $localize`OMC Name Alphabetic` },
        { id: 'lastCompleted', name: $localize`Last Completed` }
    ];

    readonly orderBy = FormPicklistComponent.make('Order By', 'orderBy', 'order',
        { items: this.orderOptions }, { formColumn: 2, valueChanges: this.updateParms.bind(this) }
    );

    readonly orderAscending: AbstractObject[] = [
        { id: 'ascending', name: $localize`Ascending` },
        { id: 'descending', name: $localize`Descending` },
    ];

    readonly orderAsc = FormPicklistComponent.make('Ascending/Descending', 'ascending', 'ascending',
        { items: this.orderAscending }, { formColumn: 2, valueChanges: this.updateParms.bind(this) }
    );

    readonly daysGrid = new GridField({
        field: { value: 'days', cellOpts: { heading: 'Schedule Days' },
            valueChanges: this.updateParms.bind(this)
        },
        rowFactory: () => {
            const fields = [
                FormTextComponent.make('Date', 'dayDtDisplay', {
                    readonly: true, calculateValue: (o) => DateHelper.longDate(o['dayDt'])
                }),
                FormTextComponent.make('Day', 'dayDt', { visible: Field.noShow }),
            ];
            this.requestOwners.forEach( owner => {
                const itemsField = FormNumberComponent.make(owner.name + ' items', 'items_' + owner.id, null, { readonly: true });
                fields.push(itemsField);
                fields.push(
                FormCheckboxComponent.make(owner.name + ' Include', 'include_'+owner.id, { valueChanges: () => {
                        setTimeout( () => this.updateParms(), 50);
                },})
                )
            })
            return fields;
        }
    });

    titleField = ServiceRequest.getTitleField().override({ formColumn: 1 });
    priorityField = ServiceRequest.getPriorityField().override({ formColumn: 1 });
    categoryField = ServiceRequest.getCategoryField().override({formColumn: 1});
    descriptionField = ServiceRequest.getDescriptionField().override({formColumn: 2})
    ownerField = FormPicklistComponent.make('Default Request Owner', 'ownerOmcRole', null, { items: OmcRole.values, allowSelectNone: true }, {formColumn: 2});
    notifyField = FormPicklistComponent.make('User to Notify on completion', 'followerOmcRole', null,
        { items: OmcRole.values, allowSelectNone: true }, { formColumn: 2, disable: true });

    readonly requestFS = new FieldSet({
        fields: [this.templateField, this.titleField, this.priorityField, this.categoryField,
            this.descriptionField, this.ownerField, this.notifyField],
        formLayout: LAYOUT_OPTIONS.doubleCol,
    });

    readonly parmsFS = new FieldSet({
        fields: [this.perDay, this.startDt, this.endDt, this.orderBy, this.orderAsc],
        formLayout: LAYOUT_OPTIONS.doubleCol,
    });

    readonly dayPlanFS = new FieldSet({
        fields: [this.daysGrid],
        formLayout: LAYOUT_OPTIONS.singleCol,
    });

    readonly requestsToCreate: ServiceRequest[] = [];
    readonly openRequests: ServiceRequest[] = [];
    readonly requestOwners: User[] = [];

    generateRequestsToCreate() {
        this.openRequests.length = 0;
        this.requestsToCreate.length = 0;

        const toDoList = this.jobsList.sort(this.sortJobs.bind(this));

        toDoList.forEach(rj => {
            if (rj.lastRequest?.srStatus === ServiceRequest.srOpen.id) {
                this.openRequests.push(this.makeRequest(rj));
            } else {
                this.requestsToCreate.push(this.makeRequest(rj));
            }
        });
        this.requestOwners.length = 0;
        this.requestsToCreate.forEach( req => {
            if (!this.requestOwners.find( o => o.id === req.ownedById)) {
                this.requestOwners.push(this.users.find(o => o.id === req.ownedById));
            }
        })

        this.gridField.setValue({ requests: this.requestsToCreate } as unknown as AbstractObject, false);
        this.skipGridField.setValue({ openRequests: this.openRequests } as unknown as AbstractObject, false);

        if (this.requestsToCreate.length === 0) {
            const msg = $localize `There are no requests to be created,
            there are ${toDoList.length} jobs created
            and ${this.openRequests.length} requests currently open for those jobs`;

            const msgDef = new MsgDef(msg, 'warn');
            this.msgService.show(msgDef);
        }
    }

    updateTemplateParams() {
        this.template.description = this.descriptionField.control.value;
        this.template.name = this.titleField.control.value;
        this.template.ownerOmcRole = this.ownerField.control.value;
        this.template.priorityId = this.priorityField.control.value;
        this.template.categoryId = this.categoryField.control.value;
    }

    onStepChange() {
        this.updateTemplateParams();
        this.updateParms();
    }

    resetBlockDays() {
        const blockDays = (this.daysGrid.control.value as DayDef[]).filter( d => {
            let isNeeded = false
            this.requestOwners.forEach( ro => {
                if (!d['include_' + ro.id]) {
                    isNeeded = true;
                }
            })
            return isNeeded;
        });

        blockDays.forEach( bd => {
            this.requestOwners.forEach (ro => {
                bd['items_'+ro.id] = 0;
            })
        })

        this.daysGrid.control.setColumns();
        this.daysGrid.setValue({days: blockDays} as unknown as AbstractObject, false);

    }

    updateParms() {
        const perDay = this.perDay.control.value;

        this.generateRequestsToCreate();
        this.resetBlockDays();


        this.requestOwners.forEach( owner => {
            let startDt = this.startDt.control.value;
            this.requestsToCreate.forEach( sr => {
                if (sr.ownedById === owner.id) {
                    startDt = this.getNextDate(startDt, perDay, owner.id);
                    this.assignSRDate(sr, startDt);
                }
            });
        });

        //this.days.setValue({ days: parmConf.days } as unknown as AbstractObject, true);
        const newDays = (this.daysGrid.control.value as DayDef[]);

        const max = newDays.reduce(function (prev, current) {
            return (prev.dayDt > current.dayDt) ? prev : current
        }, {dayDt: this.startDt.control.value});

        this.endDt.control.setValue(max.dayDt, {emitEvent: false});

        this.updateRequestsToCreateGrid();
    }

    updateRequestsToCreateGrid() {
        this.gridFS.setValue({ requests: this.requestsToCreate} as unknown as AbstractObject);
    }

    getNextDate(dayDt: string, perDay: number, ownerId: uuid) {
        if (DateHelper.isWeekend(dayDt)) {
            return this.getNextDate(DateHelper.dayAfter(dayDt), perDay, ownerId);
        }

        const scheduleDays = this.daysGrid.control.value as DayDef[];
        //const scheduleDays = this.daysGrid.getFormValue() as DayDef[];
        let day = scheduleDays.find(d => d.dayDt === dayDt);

        if (!day) {
            day = this.addBlankDayRow(dayDt, scheduleDays);
        } else {
            if (day['items_' + ownerId] >= perDay || !day['include_' + ownerId]) {
                return this.getNextDate(DateHelper.dayAfter(day.dayDt), perDay, ownerId);
            }
        }

        this.incrementDailyItemCount(scheduleDays, day, ownerId);

        return day.dayDt;
    }

    private addBlankDayRow(dayDt: string, scheduleDays: DayDef[]) : DayDef {
        const day = { dayDt, items: 0, include: true };
        this.requestOwners.forEach( o => {
            day['items_' + o.id] = 0;
            day['include_' + o.id] = true;
        });
        this.daysGrid.control.addRow(day as unknown as AbstractObject, true, false);
        scheduleDays.push(day);
        return day;
    }

    private incrementDailyItemCount(scheduleDays: DayDef[], day: DayDef, ownerId: uuid) {
        const idx = scheduleDays.indexOf(day);
        const row = (this.daysGrid.control.at(idx) as GridRow);
        const ctl = row.get('items_' + ownerId);
        ctl.setValue(ctl.value + 1, { emitEvent: false });
    }

    assignSRDate(sr, date) {
        sr.plannedDate = date;
        sr.actions.length = 0;
        ServiceRequest.templateMapper(this.template, sr, this.currentUserSvc.currentUser);
    }

    rowFactoryForRequestGrid() {

        const nextDateField = FormDateComponent.make('Planned', 'plannedDate', {
            cellOpts: { width: '8em' }, readonly: true,
        });
        // StepChange Event regenerates all, need to not regenerate if editted and step through, but still regenerate if params change
        // so for now, just making it readonly...

        return [
            FieldMaker.idHolder('teamId'),
            FieldMaker.idHolder('agentTeamId'),
            FieldMaker.idHolder('omcId'),
            FieldMaker.idHolder('templateId'),
            FieldMaker.idHolder('jobId'),
            FieldMaker.idHolder('srStatus'),
            FieldMaker.idHolder('reportedByPersonId'),
            FormTextComponent.make('OMC', 'omc.name', {readonly: true, sendServer: false}),
            FormTextComponent.make('Title', 'title', {readonly: true}),
            FormPicklistComponent.make('Priority', 'priorityId', 'priority', {items: this.priorities }, {readonly: true}),
            FormPicklistComponent.make('Category', 'categoryId', 'category', {items: this.categories}, {readonly: true}),
            FormPicklistComponent.make('OwnedBy', 'ownedById', 'ownedBy', {items: this.users}, {readonly: true}),
            nextDateField,
            FieldMaker.idHolder('actions'),
            FormTextAreaComponent.make('Description', 'description', {readonly: true})
        ];
    }

    rowFactoryOpenRequests() {

        return [
            FieldMaker.idHolder('teamId'),
            FieldMaker.idHolder('omcId'),
            FormTextComponent.make('OMC', 'omc.name', {readonly: true, sendServer: false}),
            FormDateTimeComponent.make("Created", 'lastRequest.createdAt', {readonly: true, sendServer: false}),
            FormDateTimeComponent.make("Last Update", 'lastRequest.updatedAt', {readonly: true, sendServer: false}),
        ];
    }

    readonly gridField = new GridField({
        field: { value: 'requests', cellOpts: { heading: 'Requests' } },
        rowFactory: this.rowFactoryForRequestGrid.bind(this)
    });
    readonly gridFS = new FieldSet({ fields: [this.gridField], formLayout: LAYOUT_OPTIONS.singleCol});

    readonly skipGridField = new GridField({
        field: { value: 'openRequests', cellOpts: { heading: 'Open Requests'}},
        rowFactory: this.rowFactoryOpenRequests.bind(this),
    });
    readonly skippedFS = new FieldSet({ fields: [this.skipGridField], formLayout: LAYOUT_OPTIONS.singleCol });

    readonly notCreatedStep = {
        title: $localize`Skipped`,
        help: $localize`New request will not be created for these requests because there is an existing open request`,
        fieldSet: this.skippedFS
    }
    readonly requestStep = {
        title: $localize`Request`,
        help: $localize`Please enter default values to use when generating requests.`,
        fieldSet: this.requestFS
    }
    readonly scheduleStep = {
        title: $localize`Schedule`,
        help: $localize`Please enter scheduling plan. Planned date will be allocated to requests in the order specified`,
        fieldSet: this.parmsFS
    }
    readonly requestsToCreateStep = {
        title: $localize`Requests To Create`,
        help: $localize`You can adjust the planned date and description for these requests before they are created`,
        fieldSet: this.gridFS
    }

    readonly confirmStep = {
        title: $localize`Done`,
        help: $localize`Confirm you wish to create these requests`,
        fieldSet: (new FieldSet({ fields: [] })),
        button: {label: 'Create Requests', action: this.createTheRequests.bind(this)}
    } as StepConfig;

    readonly dayPlanStep = {
        title: $localize`Daily Plan`,
        help: $localize`Review Daily Workload`,
        fieldSet: this.dayPlanFS,
    }

    readonly steps: StepConfig[] = [
        this.requestStep,
        this.notCreatedStep,
        this.scheduleStep,
        this.dayPlanStep,
        this.requestsToCreateStep,
        this.confirmStep
    ];

    users: User[] = [];
    priorities: RequestPriority[] = [];
    categories: RequestCategory[] = [];


    constructor(private _formBuilder: FormBuilder, private jobService: RecurringJobService,
        private currentUserSvc: CurrentUserService, private reqSvc: RequestService,
        teamUserSvc: UserService, reqPrioSvc: RequestPrioritiesService, reqCatSvc: RequestCategoriesService,
        @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
        private router: Router, private msgService: MessageService,
        private dialogRef: MatDialogRef<ScheduleJobsStepperComponent>,
        private isNarrowSvc: IsNarrowService) {

            teamUserSvc.getUsers().subscribe( users => {
                this.users.length = 0;
                users.forEach( u => this.users.push(u as User));
            });

            reqPrioSvc.get().subscribe ( prios => {
                this.priorities.length = 0;
                prios.forEach( p => this.priorities.push(p as RequestPriority))
            })

            reqCatSvc.get().subscribe( cats => {
                this.categories.length = 0;
                cats.forEach( c => this.categories.push(c as RequestCategory));
            })
    }

    createTheRequests() {
        this.confirmStep.button.disable = true;
        this.confirmStep.button.label = 'Saving, please wait...';

        const value = this.gridField.getFormValue();

        this.reqSvc.postItems(value).subscribe( result => {
            if (result?.itemList.length > 0) {
                this.dialogRef.close(null);
                const parms = {templateId: this.template.id, srStatus: ServiceRequest.srOpen.id}
                this.router.navigate([ServiceRequestPageComponent.navRoute.url, parms]);
            }
        })
    }

    setDefaults() {
        const defaults = {
            perDay: this.countToBeCreated(),
            templateName: this.template.name,
            startDt: DateHelper.isoDate(),
            days: [],
            orderBy: 'units',
            ascending: 'descending'
        } as ParmsConf;

        this.parmsFS.setValue(defaults);

        const month = new Date().toLocaleString('default', { month: 'long' });
        const year = new Date().getFullYear();

        const requestDefaults = {
            title: `${this.template.name} ${month} ${year}`,
            description: this.template.description,
            priorityId: this.template.priorityId,
            categoryId: this.template.categoryId,
            ownerOmcRole: this.template.ownerOmcRole,
            followerOmcRole: this.template.followerOmcRole,
        } as RequestTemplate;
        this.requestFS.setValue(requestDefaults);
    }

    makeRequest(rj: RecurringJob) {
// Shoddy, but code is somewhat duplicated in NewServiceRequestComponent.createNewSR()
        const request = new ServiceRequest();
        request.srStatus = ServiceRequest.srOpen.id;
        request.agentTeamId = this.currentUserSvc.currentUser.current_team_id;
        request.reportedByPersonId = this.currentUserSvc.currentUser.currentProfileId;
        request.teamId = rj.omc.omcTeamId;
        request.omcId = rj.omcId;
        request.omc = rj.omc;
        request.jobId = rj.id;

        request.ownedById = Omc.getUserId(rj.omc, this.template.ownerOmcRole);
        if (!request.ownedById) {
            request.ownedById = this.currentUserSvc.currentUser.id;
        }
        return request;
    }

    ngOnInit(): void {
        this.width = this.dialogOptions.width ? this.dialogOptions.width : this.isNarrowSvc.screenWidth * .8;
        this.height = this.dialogOptions.height ? this.dialogOptions.height : this.isNarrowSvc.screenHeight * .8;
        this.template = this.dialogOptions['template'];

        this.gridField.makeControl();
        this.skipGridField.makeControl();

        const jobParms = new HttpParams().set('templateId', this.template.id);

        this.jobService.get(false, jobParms).subscribe(jobs => {
            this.jobsList = jobs as RecurringJob[];
            this.setDefaults();
            this.updateParms();
            this.notCreatedStep.title = this.openRequests.length + ' ' + this.notCreatedStep.title;
            if (this.openRequests.length === 0) {
                this.notCreatedStep.help = $localize `No existing requests exist, a new request will be created for all jobs`;
            }
        });
    }

    countToBeCreated() {
        const toCreate = this.jobsList.filter ( o => {
            if (o.lastRequest && !o.lastRequest.completedAt) {
                return false;
            }
            return true;
        });
        return toCreate.length;
    }

    sortJobs(a: RecurringJob, b: RecurringJob) : number {
        const after = 1;
        const before = -1;
        const equal = 0;

        let retValue = equal;

        let sortA = a.id;
        let sortB = b.id;

        if (this.orderBy.control.value === 'units') {
            sortA = a.omc.expectedUnits;
            sortB = b.omc.expectedUnits;
        } else if (this.orderBy.control.value === 'alpha') {
            sortA = a.omc.name;
            sortB = b.omc.name;
        } else if (this.orderBy.control.value === 'lastCompleted') {
            sortA = a.lastRequest?.completedAt ? a.lastRequest.completedAt : 9999999999;
            sortB = b.lastRequest?.completedAt ? b.lastRequest.completedAt : 9999999999;
        }

        if (sortA > sortB) {
            retValue = after;
        } else if (sortA < sortB) {
            retValue = before;
        }

        if (this.orderAsc.control.value === 'descending') {
            retValue = retValue * -1;
        }

        return retValue;
    }
}
