/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Attachment } from './attachment';
import { BankAccount } from './bankAccount';
import { BCode } from './bcode';
import { Cycle } from './cycle';
import { MailItem } from './mailMerge';
import { Period } from './period';
import { Person } from './person';
import { Schedule } from './schedule';
import { Supplier } from './supplier';
import { Unit } from './unit';

export class Txn extends AbstractObject {

    bCodeId: uuid;
    bCode?: BCode;
    scheduleId?: uuid;
    schedule?: Schedule;
    personId?: uuid;
    person?: Person;
    refNr?: number;

    typeId: uuid;
    type?: AbstractObject;

    txnDate: string;
    ledgerId: uuid;
    ledger?: AbstractObject;
    txnCycleId: uuid;
    txnCycle?: Cycle;
    txnPeriodId: uuid;
    txnPeriod?: Period;

    approvedAt?: number; // date
    approvedBy?: number; // user id

    supplierId?: uuid;
    supplier?: Supplier;
    reference?: string;

    svcFrom?: string;
    svcTo?: string;

    bankAccountId?: uuid;
    bankAccount?: BankAccount;
    bankItemId?: uuid;

    debit?: number = 0;
    credit?: number = 0;
    outstanding?: number = 0;
    unallocated?: number = 0; // Not stored
    balance?: number; //not stored

    details?: Txn[] = [];

    parent?: Txn;
    parentId?: uuid;
    parentRefNr?: number;

    relations?: Txn[] = [];
    relatedId?: uuid;
    relatedRev?: number; // Not stored directly, used to ensure an update to related transaction updates correct revision.
    related?: Txn;

    lineItems?: Txn[] = []; // Not Stored merged with details on purchase invoices
    journalItems?: Txn[] = []; // Carried on lineItems to server to record relation journals.

    // Not stored, calculated total of journal entries
    totalDebit?: number;
    totalCredit?: number;

    unitId?: uuid;
    unit?: Unit;

    attachments?: Attachment[] = [];
    availableAttachments?: Attachment[] = []; // Not Stored...
    mailItems?: MailItem[] = [];

    constructor(o: Partial<Txn> = {}) {
        super(o);
    }

    /*
    * Remember types are duplicated in server/app/Models/
    */

    static readonly TYPE = {
        JRNL: { 'id': 1, 'name': 'Journal', code: 'jrnl' },
        JRNL_DETAIL: { 'id': 2, 'name': 'Journal Detail', code: 'jrnl_dtl' },
        YEAREND_DETAIL: { 'id': 3, 'name': 'Balance Forward', code: 'yend_dtl' },
        SURPLUS: { 'id': 4, 'name': 'Prev Surplus/Deficit', code: 'surplus'},

        INVOICE: { 'id': 10, 'name': $localize`Service Charge`, code: 'inv' },
        INVOICE_ITEM: { 'id': 11, 'name': $localize`Service Charge Item`, code: 'inv_item' },
        CREDIT_NOTE: { 'id': 12, 'name': $localize`Svc Credit Note`, code: 'cn' },
        CREDIT_NOTE_ITEM: { 'id': 13, 'name': $localize`Svc Credit Detail`, code: 'cn_item' },
        REVERSAL: { 'id': 14, 'name': $localize`Payment Reversal`, code: 'reverse' },
        REVERSAL_DTL: { 'id': 15, 'name': $localize`Reversal Detail`, code: 'rev_dtl' },
        REVERSAL_FEE: { 'id': 16, 'name': $localize`Payment Reversal Fee`, code: 'rev_fee' },
        REV_FEE_DTL: { 'id': 17, 'name': $localize`Reversal Fee Detail`, code: 'rev_feed' },


        PURCHASE: { 'id': 40, 'name': $localize`Purchase Invoice`, code: 'pur' },
        PURCHASE_ITEM: { 'id': 41, 'name': $localize`Purchase Inv. Dtl`, code: 'pur_item' },
        PURCHASE_CN: { 'id': 42, 'name': $localize`Purchase Credit Note`, code: 'ref' },
        PURCHASE_CN_ITEM: { 'id': 43, 'name': $localize`Purchase Credit Dtl`, code: 'ref_item' },

        PURCHASE_ACCRUAL: { 'id': 45, 'name': 'Accrued Expense', code: 'jrnl' },

        PREPAY_REV_EXP: { 'id': 50, 'name': 'Reverse Expense', code: 'jrnl' },
        PREPAY_ASSET: { 'id': 51, 'name': 'Prepay Asset', code: 'jrnl' },
        PREPAY_WRITEDOWN: { 'id': 52, 'name': 'Writedown Prepayment', code: 'jrnl' },
        PREPAY_CHARGE: { 'id': 53, 'name': 'Prepaid Expense Charge', code: 'jrnl' },

        BANK_IN: { 'id': 70, 'name': $localize`Bank Receipt`, code: 'bank_in' },
        BANK_IN_ALLOC: { 'id': 71, 'name': $localize`Receipt Allocation`, code: 'rec_alloc' },
        BANK_IN_ON_ACCT: { 'id': 72, 'name': $localize`Receipt On Acct`, code: 'rec_acct' },

        BANK_OUT: { 'id': 75, 'name': $localize`Bank Payment`, code: 'bank_out' },
        BANK_OUT_ALLOC: { 'id': 76, 'name': $localize`Payment Allocation`, code: 'bank_out_alloc' },
        BANK_CHARGE: { 'id': 77, 'name': $localize`Bank Charge`, code: 'bank_chg' },
        BANK_CHG_DTL: { 'id': 78, 'name': $localize`Bank Charge Detail`, code: 'bank_chgd' },

        BANK_TRAN: { 'id': 79, 'name' : $localize `Bank Transfer`, code: 'bank_tran'},
        BANK_TRAN_IN: { 'id': 80, 'name': $localize`Transfer In`, code: 'bank_tr_in' },
    }

    static readonly TYPES = [
        Txn.TYPE.JRNL,
        Txn.TYPE.JRNL_DETAIL,
        Txn.TYPE.YEAREND_DETAIL,
        Txn.TYPE.SURPLUS,

        Txn.TYPE.INVOICE,
        Txn.TYPE.INVOICE_ITEM,
        Txn.TYPE.CREDIT_NOTE,
        Txn.TYPE.CREDIT_NOTE_ITEM,
        Txn.TYPE.REVERSAL,
        Txn.TYPE.REVERSAL_DTL,
        Txn.TYPE.REVERSAL_FEE,
        Txn.TYPE.REV_FEE_DTL,

        Txn.TYPE.PURCHASE,
        Txn.TYPE.PURCHASE_ITEM,
        Txn.TYPE.PURCHASE_CN,
        Txn.TYPE.PURCHASE_CN_ITEM,

        Txn.TYPE.PURCHASE_ACCRUAL,

        Txn.TYPE.PREPAY_REV_EXP,
        Txn.TYPE.PREPAY_ASSET,
        Txn.TYPE.PREPAY_WRITEDOWN,
        Txn.TYPE.PREPAY_CHARGE,

        Txn.TYPE.BANK_IN,
        Txn.TYPE.BANK_IN_ALLOC,
        Txn.TYPE.BANK_IN_ON_ACCT,
        Txn.TYPE.BANK_OUT,
        Txn.TYPE.BANK_OUT_ALLOC,
        Txn.TYPE.BANK_CHARGE,
        Txn.TYPE.BANK_CHG_DTL,
        Txn.TYPE.BANK_TRAN,
        Txn.TYPE.BANK_TRAN_IN
    ];

    static readonly LEDGER = {
        GL: { 'id': 99, 'name': $localize`Journals`, code: 'gl' },
        AR: { 'id': 1, 'name': $localize`Service Charge Accounting`, code: 'ar' },
        AP: { 'id': 4, 'name': $localize`Payables`, code: 'ap' },
        BL: { 'id': 7, 'name': $localize`Bank`, code: 'bl' },
        AA: { 'id': 9, 'name': $localize`Assets`, code: `aa` },
    }

    static readonly LEDGERS = [
        Txn.LEDGER.GL,
        Txn.LEDGER.AR,
        Txn.LEDGER.AP,
        Txn.LEDGER.BL,
        Txn.LEDGER.AA
    ];

    static getType(id: uuid) {
        return Txn.TYPES.find(type => type.id === id);
    }

    static DELETE_MSG = $localize`
        Cannot truly delete transaction for audit reasons.
        If you delete transaction it will still be visible,
        but it's value is reduced to zero so overall numbers are not effected.
        If you wish to continue, enter a reason and press the delete option`;

    static getTxnLink(o: Txn) {
        if (o) {
            if (o.typeId === Txn.TYPE.INVOICE.id) {
                return `/${Txn.TYPE.INVOICE.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.INVOICE_ITEM.id) {
                return `/${Txn.TYPE.INVOICE.code}/${o.parentId}`

            } else if (o.typeId === Txn.TYPE.BANK_IN.id) {
                return `/${Txn.TYPE.BANK_IN.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.BANK_IN_ALLOC.id || o.typeId == Txn.TYPE.BANK_IN_ON_ACCT.id) {
                return `/${Txn.TYPE.BANK_IN.code}/${o.parentId}`

            } else if (o.typeId === Txn.TYPE.JRNL.id) {
                return `/${Txn.TYPE.JRNL.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.JRNL_DETAIL.id) {
                return `/${Txn.TYPE.JRNL.code}/${o.parentId}`

            } else if (o.typeId === Txn.TYPE.CREDIT_NOTE.id) {
                return `/${Txn.TYPE.CREDIT_NOTE.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.CREDIT_NOTE_ITEM.id) {
                return `/${Txn.TYPE.CREDIT_NOTE.code}/${o.parentId}`

            } else if (o.typeId === Txn.TYPE.PURCHASE.id) {
                return `/${Txn.TYPE.PURCHASE.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.PURCHASE_ITEM.id) {
                return `/${Txn.TYPE.PURCHASE.code}/${o.parentId}`

            } else if (o.typeId === Txn.TYPE.PURCHASE_CN.id) {
                return `/${Txn.TYPE.PURCHASE_CN.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.PURCHASE_CN_ITEM.id) {
                return `/${Txn.TYPE.PURCHASE_CN.code}/${o.parentId}`

            } else if (o.typeId === Txn.TYPE.BANK_OUT_ALLOC.id) {
                return `/${Txn.TYPE.BANK_OUT.code}/${o.parentId}`
            } else if (o.typeId === Txn.TYPE.BANK_OUT.id) {
                return `/${Txn.TYPE.BANK_OUT.code}/${o.id}`
            } else if (o.typeId === Txn.TYPE.PREPAY_WRITEDOWN.id
            || o.typeId === Txn.TYPE.PREPAY_CHARGE.id
            || o.typeId === Txn.TYPE.PREPAY_ASSET.id
            || o.typeId === Txn.TYPE.PREPAY_REV_EXP.id) {
                return `/${Txn.TYPE.PURCHASE.code}/${o.parentId}`
            } else {
                console.warn('dunnohowtolinkto');
                return '/dunnohowtolinkto';
            }
        } else {
            return 'onHeader';
        }
    }
}
